const weekDays = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0,
}

export const getDateOfNextWeekDay = (weekdayIndex: string) => {
  if (!Object.prototype.hasOwnProperty.call(weekDays, weekdayIndex)) {
    return null
  }

  const now = new Date()
  now.setDate(
    now.getDate() +
      ((weekDays[weekdayIndex as keyof typeof weekDays] + (7 - now.getDay())) % 7)
  )
  return now.toISOString().split('T')[0]
}
