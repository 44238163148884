import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import React, { useEffect } from 'react'
import {
  defState,
  LevelOfAuditorMappingItem,
  LevelOfAuditorStateT,
  mapping,
} from './model'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { CountryPolicyContextType } from 'pages/advanced-credit-policy/types'
import { Typography } from '@mui/material/'

const options = [
  {
    label: 'NO AUDITOR ASSISTANCE',
    value: 'noAuditorAssistance',
  },
  {
    label: "AUDITOR'S REPORTS (OTHER NON-ASSURANCE REPORTS)",
    value: 'otherNonAssuranceReports',
  },
  {
    label: "THE INDEPENDENT AUDITOR'S REPORTS (REVIEW)",
    value: 'review',
  },
  {
    label: "AUDITOR'S REPORT ON EXTENDED REVIEW",
    value: 'extendedReview',
  },
  {
    label: "AUDITOR'S REPORT",
    value: 'auditorsReport',
  },
  {
    label: 'COMPILATION OF FINANCIAL STATEMENTS',
    value: 'compilationFinancialStatements',
  },
] as const

type LevelOfAuditorAssistancesProps = {
  policyInject: () => CountryPolicyContextType
  isAdvanced?: boolean
}
const LevelOfAuditorAssistances = ({
  policyInject,
  isAdvanced = true,
}: LevelOfAuditorAssistancesProps) => {
  const { policy, setPolicy, isInferiorPlan } = policyInject()
  const [state, setState] = React.useState<LevelOfAuditorStateT>(defState)
  const isDisabled = isAdvanced && isInferiorPlan

  /**
   * Populates blank skeleton local state with policy values
   * in appropriate order, following the mapping schema
   * @param  {LevelOfAuditorStateT} structureState this is the local state skeleton
   * @param  {string[]} checkedList Policy field that represents current state
   * @param  {LevelOfAuditorMappingItem[]} mappingSchema Connection between state and policy field
   * @return {LevelOfAuditorStateT} Returns the new state
   */
  const populate = (
    structureState: LevelOfAuditorStateT,
    checkedList: string[],
    mappingSchema: LevelOfAuditorMappingItem[]
  ): LevelOfAuditorStateT => {
    const result = { ...structureState }
    checkedList.forEach((checkItem) => {
      const checkedMappedItem = mappingSchema.find(
        (mapItem) => mapItem.label === checkItem
      )
      result[checkedMappedItem?.stateField!] = true
    })
    return result
  }

  /**
   * Convert local state (Level of Auditor Assistances) to string array
   * in appropriate order, following the mapping schema
   * @param {LevelOfAuditorStateT} currentState
   * @param {LevelOfAuditorMappingItem[]} mappingSchema
   * @returns {string[]}
   */
  const convertToArr = (
    currentState: LevelOfAuditorStateT,
    mappingSchema: LevelOfAuditorMappingItem[]
  ): string[] => {
    const result: string[] = []
    mappingSchema.forEach((mappingItem) => {
      if (currentState[mappingItem.stateField]) {
        result.push(mappingItem.label)
      }
    })
    return result
  }

  useEffect(() => {
    if (policy?.exclude_level_of_auditor_assistances) {
      setState(populate(defState, policy.exclude_level_of_auditor_assistances, mapping))
    }
  }, [policy?.exclude_level_of_auditor_assistances])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { ...state, [event.target.name]: event.target.checked }
    setState(newState)
    const newStr = convertToArr(newState, mapping)
    setPolicy({ ...policy, exclude_level_of_auditor_assistances: newStr })
  }

  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          control={
            <Checkbox
              checked={state[option.value]}
              name={option.value}
              onChange={handleChange}
              disabled={isDisabled}
              sx={{ margin: 3, padding: 0 }}
            />
          }
          label={
            <Typography color="grey.800" variant="body1">
              <FormattedMessage id={intl.creditPolicy(option.label)} />
            </Typography>
          }
        />
      ))}
    </FormGroup>
  )
}

export default LevelOfAuditorAssistances
