import {
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { useParams } from 'react-router-dom'

import { useIndustryList } from 'services/queries'
import { useBuilder } from '../../../../../BuilderContext'
import { parseIndustryTree } from 'pages/advanced-filters/scenes/NestedSelect/NestedIndustrySelect'
import { IndustryTypeView } from 'components-new'
import { addSelectAllToIndustryTypeData } from './Actions.controller'
import { useIntl } from 'react-intl'
import { getParentAndDetermineState } from 'pages/advanced-filters/scenes/NestedSelect/utils'
import { IndustryTypePropsType } from './Actions.types'
import { IndustryTypeParsedData } from 'components-new/elements/IndustryTypeView/IndustryTypeView.types'

const IndustryType = (
  { setIsConfirmEnabled, apiData }: IndustryTypePropsType,
  ref: ForwardedRef<any>
) => {
  const { country } = useParams<{ country: 'DK' | 'SE' | 'NO' }>()
  const [industryTypeList, setIndustryTypeList] = useState<string[]>([])
  const [industryTypeListApiData, setIndustryTypeListApiData] = useState<string[]>([])
  const { data, isLoading } = useIndustryList(country.toLowerCase() as 'dk' | 'se' | 'no')
  const [expanded, setExpanded] = useState(false)
  const { state, actions } = useBuilder()
  const { messages } = useIntl()

  useEffect(() => {
    setIsConfirmEnabled(Boolean(industryTypeList.length) && !expanded)
  }, [expanded, industryTypeList, setIsConfirmEnabled])

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit: () => {
        actions.setNodeApiData({
          nodeId: state.actionPanelData.nodeId,
          data: {
            category: 'industry_code',
            include: industryTypeListApiData.filter((item) => item !== 'ALL'),
          },
        })
      },
    }),
    [actions, industryTypeList, state.actionPanelData.nodeId]
  )

  const parsedData: IndustryTypeParsedData = useMemo(
    () => addSelectAllToIndustryTypeData(parseIndustryTree(data), messages),
    [data, messages]
  )

  const leafItems = apiData?.include
    ?.map((item) => parsedData[item])
    .filter((item) => item.isLeaf)
    .map((item) => item.value)

  leafItems?.forEach((item) => (parsedData[item].state = 'checked'))
  leafItems?.forEach((leaf) => getParentAndDetermineState(leaf, parsedData))

  if (isLoading && !data) {
    return <h1>Loading...</h1>
  }

  return parsedData ? (
    <>
      <IndustryTypeView
        data={parsedData}
        industryTypeList={industryTypeList}
        setIndustryTypeList={setIndustryTypeList}
        setApiData={setIndustryTypeListApiData}
        expanded={expanded}
        setExpanded={setExpanded}
        data-cy="industry-type"
      />
    </>
  ) : (
    <div>Loading...</div>
  )
}
export default forwardRef(IndustryType)
