import { useEffect } from 'react'
import { useUserSelf } from 'services/queries'
import { initializeHubSpot } from './HomePage.controller'
import { Stack } from '@mui/material'
import VisitsSummary from './widgets/VisitsSummary'
import VisitsList from './widgets/VisitsList'
import { ErrorBoundary } from 'components'

const HomePage = () => {
  const { data } = useUserSelf()

  // HubSpot survey
  useEffect(() => {
    initializeHubSpot(data)
  }, [data])

  return (
    // @ts-expect-error
    <ErrorBoundary page="hope-page">
      <Stack py={6} px={7} spacing={5}>
        <VisitsSummary />
        <VisitsList />
      </Stack>
    </ErrorBoundary>
  )
}

export default HomePage
