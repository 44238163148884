// @flow
import ReactTable from 'react-table'
import { injectIntl } from 'react-intl'
import intl from 'localization/components'
import TablePaginationToolbar from 'components-new/data-display/Table/elements/TablePaginationToolbar'

const IntlReactTable = (props) => {
  const {
    intl: { messages },
  } = props

  const intlProps = {
    previousText: messages[intl.table('previous')],
    nextText: messages[intl.table('next')],
    loadingText: messages[intl.table('loading')],
    noDataText: messages[intl.table('no-rows')],
    pageText: messages[intl.table('page')],
    ofText: messages[intl.table('of')],
    rowsText: messages[intl.table('rows')],
  }

  const handlePagination = (paginationProps) => {
    const { page, pages, pageSize, onPageChange, onPageSizeChange, pageSizeOptions } =
      paginationProps
    const normalizedPage = page + 1 // react-table page starts from 0
    return (
      <TablePaginationToolbar
        page={normalizedPage}
        totalPages={pages}
        handlePage={(newPage) => onPageChange(newPage - 1)} // react-table page starts from 0
        perPageLimit={pageSize}
        handlePageLimit={(limit) => onPageSizeChange(limit)}
        pageLimits={pageSizeOptions}
      />
    )
  }

  return <ReactTable PaginationComponent={handlePagination} {...props} {...intlProps} />
}

export default injectIntl(IntlReactTable)
