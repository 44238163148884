import { Box } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

const ChartWithTextSkeleton = ({ height }: { height: number }) => (
  <Box p={2}>
    {Array.from(Array(3)).map((item, i) => (
      <Box height={100} sx={{ display: 'flex', alignItems: 'center' }} key={i}>
        <Skeleton variant="circular" width={60} height={50} sx={{ mr: 2 }} />
        <Skeleton width="100%" height={100} />
      </Box>
    ))}
  </Box>
)
export default ChartWithTextSkeleton
