import { Divider, Paper, Stack, Typography } from '@mui/material'

type PaperContainerProps = {
  title: React.ReactNode
  children: React.ReactNode
  customHeaderComponent?: React.ReactNode
  height?: string
}

function PaperContainer({
  title,
  children,
  customHeaderComponent,
  height,
}: PaperContainerProps) {
  return (
    <Paper sx={{ minHeight: height ?? 'auto' }} component={Stack}>
      <Stack
        sx={{ height: '58px' }}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        p={4}
      >
        <Typography variant="h5">{title}</Typography>
        {customHeaderComponent}
      </Stack>
      <Divider />
      {children}
    </Paper>
  )
}

export default PaperContainer
