import React from 'react'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
} from '@mui/material'

import { Sidescroll } from 'components'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const TableHandler = ({ titles, data, isPhone, width = '100%' }) => {
  return (
    <Box>
      {isPhone ? (
        <Sidescroll conditionalySidescroll="true">
          <Table>
            <TableHead>
              <TableRow>
                {titles.map((x) => (
                  <TableCell key={x}>
                    <Typography variant="subtitle1">
                      <FormattedMessage id={intl.faq(x)} />
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ? data?.map((x, i) => (
                    <TableRow key={i}>
                      {titles.map((t) => (
                        <TableCell key={x[t]}>
                          <Typography variant="body2">{x[t]}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : ''}
            </TableBody>
          </Table>
        </Sidescroll>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {titles.map((x) => (
                <TableCell key={x}>
                  <Typography variant="subtitle1">
                    <FormattedMessage id={intl.faq(x)} />
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ? data.map((x, i) => (
                  <TableRow key={i}>
                    {titles.map((t) => (
                      <TableCell key={x[t]}>
                        <Typography variant="body2">{x[t]}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : ''}
          </TableBody>
        </Table>
      )}
    </Box>
  )
}

export default TableHandler
