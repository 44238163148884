import React from 'react'
import { makeStyles } from '@mui/styles'

import { ConditionallyRender, ShowHideAllWrapper, CopyItem } from 'components'
import SimpleEntry from './SimpleEntry'

import { Tooltip, Box, Typography } from '@mui/material'

import moment from 'moment'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { handleAddressField } from '../../../../../utils'

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
  invalid: {
    color: 'gray',
  },
  space: {
    marginLeft: '0.4rem',
  },
  clipboardSpacing: {
    display: 'inline',
    marginLeft: theme.spacing(1),
  },
}))

const ProductionUnits = ({ sortedProductionUnits, address }) => {
  const classes = useStyles()
  const [isShowAllProductionUnits, setIsShowAllProductionUnits] = React.useState(false)
  return (
    <ConditionallyRender
      condition={!!sortedProductionUnits?.length}
      when={
        <div style={{ margin: '0 0 1rem 0' }}>
          <SimpleEntry
            title={<FormattedMessage id={intl.companyInfo('production-units')} />}
            elementType="div"
            content={[
              sortedProductionUnits?.map((entry, i) => (
                <ShowHideAllWrapper
                  setIsShowAll={setIsShowAllProductionUnits}
                  isShowAll={isShowAllProductionUnits}
                  key={i}
                  amountOfShowedItems={3}
                  iteration={i}
                  dataLength={sortedProductionUnits?.length}
                >
                  <div style={{ display: 'flex' }}>
                    <Tooltip
                      title={
                        <>
                          <Typography variant="body2">
                            <b>
                              {<FormattedMessage id={intl.companyInfo('industry')} />}
                              {': '}
                            </b>
                            {entry.industry_description}
                          </Typography>
                          <Typography variant="body2">
                            <b>
                              {<FormattedMessage id={intl.companyInfo('employees')} />}
                              {': '}
                            </b>
                            {entry.fte_interval}
                          </Typography>
                          <Typography variant="body2">
                            <b>
                              {<FormattedMessage id={intl.companyInfo('fte')} />}
                              {': '}
                            </b>
                            {entry.employees_interval}
                          </Typography>
                          <Typography variant="body2">
                            <b>
                              {<FormattedMessage id={intl.companyInfo('valid-from')} />}
                              {': '}
                            </b>
                            {moment(entry.valid_from).format('DD.MM.YYYY')}
                          </Typography>
                          <ConditionallyRender
                            condition={entry.valid_to}
                            when={
                              <Typography variant="body2">
                                <b>
                                  {<FormattedMessage id={intl.companyInfo('valid-to')} />}
                                  {': '}
                                </b>
                                {moment(entry.valid_to).format('DD.MM.YYYY')}
                              </Typography>
                            }
                          />
                        </>
                      }
                      placement="top"
                    >
                      <div
                        style={{ display: 'flex', cursor: 'default' }}
                        className={entry.invalid ? classes.invalid : classes.valid}
                      >
                        <Typography variant="body2">{`${entry.name} (${
                          entry.unit_id
                        }) ${handleAddressField(entry.address)}`}</Typography>
                        <Box className={classes.clipboardSpacing}>
                          <CopyItem
                            item={`${entry.name} (${entry.unit_id}) ${handleAddressField(
                              entry.address
                            )}`}
                          />
                        </Box>
                        <ConditionallyRender
                          condition={entry.invalid}
                          when={
                            <Typography variant="body2" className={classes.space}>
                              {<FormattedMessage id={intl.companyInfo('invalid')} />}
                            </Typography>
                          }
                        />
                      </div>
                    </Tooltip>
                  </div>
                </ShowHideAllWrapper>
              )),
            ]}
          />
          <></>
        </div>
      }
    />
  )
}

export default ProductionUnits
