import { useState, useEffect } from 'react'
import moment from 'moment'
import SideDrawer from 'components-new/layouts/SideDrawer'
import { Stack, Typography, Divider } from '@mui/material'
import styles from '../SidePanels.styles'
import {
  ButtonSecondary,
  ButtonTertiary,
  DropdownSelect,
  InputArea,
  InputField,
} from 'components-new'
import { PropsType } from './EditPolicySidePanel.types'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { useCreditPolicyGet, useCreditPolicyUpdate } from 'services/queries'
import { navigation } from 'services/navigation'
import { useHistory } from 'react-router-dom'
import { selectCountriesOptions } from '../CreatePolicySidePanel/CreatePolicySidePanel.model'
import SidePanelHeader from '../../PolicyBuilder/widgets/ActionPanel/widgets/SidePanelHeader'

const { container, inputContainer } = styles

const EditPolicySidePanel = ({ isOpen, setIsOpen, policyId }: PropsType) => {
  // const [editMode, setEditMode] = useState(true)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const history = useHistory()

  const creditPolicy = useCreditPolicyGet(policyId)
  const { mutateAsync } = useCreditPolicyUpdate()
  useEffect(() => {
    if (creditPolicy.isSuccess && creditPolicy.data) {
      const { name, description } = creditPolicy.data
      setName(name)
      setDescription(description)
    }
  }, [creditPolicy.data, creditPolicy.isSuccess])

  // Create proper loading skeleton
  if (creditPolicy.isLoading || !creditPolicy.isSuccess) {
    return <div>Loading...</div>
  }

  const { updated, country, created } = creditPolicy.data

  const handleClose = () => setIsOpen(false)

  const handleSave = () => {
    if (creditPolicy.data) {
      const updateData = {
        country: creditPolicy.data.country,
        name,
        description,
      }
      const navigationData = {
        ...updateData,
        createActiveRule: 'false',
        policyId: creditPolicy.data.id,
      }
      mutateAsync({ id: Number(policyId), policyData: updateData })
      const landingPage = navigation.enterpriseCreditPolicy()
      if (history.location.pathname.includes(landingPage)) {
        history.push(landingPage)
      } else {
        history.push(navigation.creditPolicyBuilder({ ...navigationData }))
      }
    }
    setIsOpen(false)
  }

  return (
    <SideDrawer
      drawerState={{
        drawerIsOpen: isOpen,
        setDrawerIsOpen: setIsOpen,
      }}
      side="right"
    >
      <Stack sx={container} data-cy="credit-policy-panel-edit">
        <SidePanelHeader
          title={<FormattedMessage id={intl.creditPolicyNew('edit-policy-settings')} />}
          onClose={handleClose}
        />
        <Divider />

        <Stack sx={inputContainer}>
          <InputField
            data-cy="credit-policy-name"
            label={<FormattedMessage id={intl.generic('name')} />}
            value={name}
            handleChange={setName}
          />
          <DropdownSelect
            disabled
            options={selectCountriesOptions}
            value={country}
            setValue={() => {}}
            inputProps={{ label: <FormattedMessage id={intl.generic('country')} /> }}
          />
          <InputArea
            data-cy="policy-settings-description"
            maxCharacters={300}
            value={description}
            setValue={setDescription}
            label={<FormattedMessage id={intl.creditPolicyNew('description-text')} />}
          />
          <Divider light={true} />
          <Stack spacing={5}>
            <Stack
              data-cy="policy-settings-created"
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{ width: '50%', color: 'grey.600' }} variant="subtitle1">
                <FormattedMessage id={intl.creditPolicyNew('created-on')} />
              </Typography>
              <Typography sx={{ width: '100%' }} textAlign="left" variant="body1">
                {moment(created).format('DD.MM.YYYY')}
              </Typography>
            </Stack>
            <Stack
              data-cy="policy-settings-updated"
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{ width: '50%', color: 'grey.600' }} variant="subtitle1">
                <FormattedMessage id={intl.creditPolicyNew('last-updated')} />
              </Typography>
              <Typography sx={{ width: '100%' }} textAlign="left" variant="body1">
                {moment(updated).format('DD.MM.YYYY')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          paddingY={4}
          paddingX={5}
          justifyContent="space-between"
          sx={{ marginTop: 'auto', backgroundColor: 'grey.50' }}
        >
          <ButtonTertiary data-cy="cancel-changes-button" onClick={handleClose}>
            {<FormattedMessage id={intl.generic('cancel')} />}
          </ButtonTertiary>
          <ButtonSecondary
            data-cy="save-changes-button"
            disabled={!name}
            onClick={handleSave}
          >
            {<FormattedMessage id={intl.riskMonitoringNew('save-changes')} />}
          </ButtonSecondary>
        </Stack>
      </Stack>
    </SideDrawer>
  )
}

export default EditPolicySidePanel
