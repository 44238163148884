import axios from 'axios'
import { CreditOverrideFormState } from './CreditOverrideForm.types'
import { env } from 'services/env'
import { CurrencyObj } from 'services/types'
import { convertCurrency } from 'services/api'
import { getAccountStatusData } from 'utils/getAccountStatusData'

const baseId = env.AIR_TABLE_BASE_ID
const tableIdOrName = env.AIR_TABLE_TABLE_NAME
const authToken = env.AIR_TABLE_AUTH_TOKEN
type OverridenOutcome = 'Credit approved' | 'Manual check' | 'Credit rejected'
type OverrideRequestStatus = 'pending' | 'underReview' | 'active' | 'inactive'

interface ResponseFields extends CreditOverrideFormState {
  overriddenCreditLimit: string
  overriddenOutcome: OverridenOutcome
  feedback: string
  requestStatus: OverrideRequestStatus
  currency: string
}

export type AirTableRecord = {
  createdTime: Date
  fields: ResponseFields
  id: string
}

export type AirTableResponse = {
  config: any
  data: {
    records: AirTableRecord[]
  }
  headers: any
  request: any
  status: number
  statusText: string
}

type PostRequestData = {
  records: {
    fields: CreditOverrideFormState
  }[]
}

type GetRequestData = {
  localId?: string
  latest?: boolean
}

export type OverrideData = {
  localId: string
}

type Outcome =
  | 'approve'
  | 'approve_with_value'
  | 'adjust_credit_max'
  | 'manual_check'
  | 'reject'
  | 'default'

export type OverrideResponse = {
  outcome?: Outcome
  outcomeName?: string
  explanation?: string
  creditLimit?: number | null
  currency?: string
  status: OverrideRequestStatus
}

type GetOverrideRequestData = {
  localId: string
  selectedCurrency: CurrencyObj
}

// Get an active override for a company based on localId
export const getOverride = async ({
  localId,
  selectedCurrency,
}: GetOverrideRequestData) => {
  const params = localId
    ? {
        filterByFormula: `AND(SEARCH('${localId}', {localId}))`,
      }
    : {}

  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    params,
  }
  const status = getAccountStatusData()
  const url = `https://api.airtable.com/v0/${
    baseId
    // status?.credit_override_config?.app_name || baseId
  }/${tableIdOrName}`
  try {
    const response: AirTableResponse = await axios.get(url, config)
    const activeOverrideRequest = response.data.records.filter((record: any) => {
      return record.fields.requestStatus === 'active'
    })
    if (activeOverrideRequest.length) {
      const {
        overriddenCreditLimit,
        overriddenOutcome,
        feedback,
        requestStatus,
        currency,
      } = activeOverrideRequest[0].fields
      if (currency !== selectedCurrency.currency) {
        const converted = await convertCurrency({
          base: currency,
          to: selectedCurrency.currency,
          value: Number(overriddenCreditLimit),
        })
        return {
          overriddenCreditLimit: converted,
          overriddenOutcome,
          feedback,
          requestStatus,
          currency: selectedCurrency.currency,
        }
      }
      return {
        overriddenCreditLimit,
        overriddenOutcome,
        feedback,
        requestStatus,
        currency,
      }
    }
    return null
  } catch (error) {
    throw new Error('Error getting override')
  }
}

// Check if there is a request with requestStatus = pending or underReview,
// so we can disable the Request override button.This function returns boolean.
export const getPendingOrUnderReviewOverride = async ({ localId }: OverrideData) => {
  const params = localId
    ? {
        filterByFormula: `AND(SEARCH('${localId}', {localId}))`,
      }
    : {}

  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    params,
  }
  const status = getAccountStatusData()
  const url = `https://api.airtable.com/v0/${
    baseId
    // status.credit_override_config?.app_name || baseId
  }/${tableIdOrName}`
  try {
    const response: AirTableResponse = await axios.get(url, config)
    const pendingOrUnderReviewRequests = response.data.records.filter((record: any) => {
      return (
        record.fields.requestStatus === 'pending' ||
        record.fields.requestStatus === 'underReview'
      )
    })
    if (pendingOrUnderReviewRequests.length) {
      return true
    }
  } catch (error) {
    throw new Error('Error getting request')
  }
  return false
}

export const postData = (data: PostRequestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  }
  const status = getAccountStatusData()
  const url = `https://api.airtable.com/v0/${
    baseId
    // status.credit_override_config?.app_name || baseId
  }/${tableIdOrName}`
  return axios
    .post(url, data, config)
    .then((response) => {})
    .catch((error) => {
      throw new Error('Error creating records: ', error.message)
    })
}

// GET the latest override request for a company
export const getLatestOverrideRequest = async ({ localId, latest }: GetRequestData) => {
  const params = localId
    ? {
        filterByFormula: `SEARCH('${localId}', {localId})`,
      }
    : {}

  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    params,
  }
  const status = getAccountStatusData()
  const url = `https://api.airtable.com/v0/${
    baseId
    // status.credit_override_config?.app_name || baseId
  }/${tableIdOrName}`
  try {
    const response = await axios.get(url, config)
    if (latest) {
      const sortedRecords = response.data.records.sort((a: any, b: any) => {
        return new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime()
      })
      return sortedRecords[0]
    }
    return response.data.records
  } catch (error) {
    console.log('Error: ')
  }
}
