import { authRoles } from 'components/auth'
import FaqPage from './FaqPage'
import { navigation } from 'services/navigation'
export const FaqPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: navigation.faq(),
      component: FaqPage,
    },
  ],
}
