import { useMemo, useReducer, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { motion } from 'framer-motion'
import { validate, pipeValidators, validationFormats } from 'utils/validate'
import { createReducer } from 'store_deprecated/reducer'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { Button, Stack } from 'components'
import { activateAccount } from 'services/api'
import { activateUserSuccess } from 'store_deprecated/auth/actions'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { useStyles } from './ChangePasswordFlow.styles'
import { useQueryClient } from 'react-query'

const passwordForm = createReducer({
  password: '',
  confirmPassword: '',
})

const passwordErrorForm = createReducer({
  password: false,
  confirmPassword: false,
})

const PASSWORD = 'password'
const CONFIRM_PASSWORD = 'confirmPassword'
export default function ChangePassword({ isOpen }) {
  const classes = useStyles()
  const reactIntl = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { verifyToken } = useSelector((state) => state?.auth?.login)

  const [formState, formDispatch] = useReducer(...passwordForm)
  const [formErrorState, formErrorDispatch] = useReducer(...passwordErrorForm)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  /** Validators */
  const passwordValidation = useMemo(() => ({ length: { min: 8 } }), [])
  const confirmPasswordValidation = useCallback(
    (passwordToMatch) =>
      pipeValidators(passwordValidation, validationFormats.matchValue(passwordToMatch)),
    [passwordValidation]
  )

  useEffect(() => {
    const allFieldsValid = [
      validate(formState.password, passwordValidation),
      validate(formState.confirmPassword, confirmPasswordValidation(formState.password)),
    ]

    const isValid = allFieldsValid.filter((item) => !item).length === 0

    setIsFormValid(isValid)
  }, [formState, confirmPasswordValidation, passwordValidation])

  const handleOnChange = (field) => (event) => {
    const { value } = event.target
    if (field === 'password') {
      const isValid = validate(value, passwordValidation)

      if (isValid) {
        formErrorDispatch({ field, value: !isValid })
      }
    } else if (field === 'confirmPassword') {
      const isValid = validate(value, confirmPasswordValidation(formState.password))

      if (isValid) {
        formErrorDispatch({ field, value: !isValid })
      }
    }

    formDispatch({ field, value })
  }

  const handleOnLeave = (field) => (event) => {
    const { value } = event.target

    if (field === 'password') {
      const isValid = validate(value, passwordValidation)

      formErrorDispatch({ field, value: !isValid })
    } else if (field === 'confirmPassword') {
      const isValid = validate(value, confirmPasswordValidation(formState.password))

      formErrorDispatch({ field, value: !isValid })
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const { password, confirmPassword } = formState

    const ARTIFICIAL_DELAY = 2000

    setIsLoading(true)

    setTimeout(() => {
      activateAccount({ password, confirmPassword, verifyToken: verifyToken || null })
        .then(() => {
          enqueueSnackbar(
            <FormattedMessage id={intl.snackbar('account-activated-success')} />,
            { variant: 'success' }
          )
          queryClient.refetchQueries('userSelf')
          dispatch(activateUserSuccess())
        })
        .catch((e) => {
          switch (e?.response?.data?.code) {
            case 'password_not_allowed':
              enqueueSnackbar(
                <FormattedMessage id={intl.snackbar('password-not-allowed')} />,
                { variant: 'error' }
              )
              break
            default:
              enqueueSnackbar(
                <FormattedMessage id={intl.snackbar('account-activated-failed')} />,
                { variant: 'error' }
              )
              break
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, ARTIFICIAL_DELAY)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <motion.div
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        initial="visible"
        animate={isOpen ? 'visible' : 'hidden'}
        className="here"
      >
        <DialogTitle style={{ paddingBottom: 0 }}>
          <FormattedMessage id={intl.firstTimeVisit('title')} />
        </DialogTitle>
        <DialogTitle style={{ paddingTop: 0, paddingBottom: 0 }} disableTypography>
          <DialogContentText>
            <FormattedMessage id={intl.firstTimeVisit('description')} />
          </DialogContentText>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2} direction="column">
              <TextField
                autoFocus
                value={formState.password}
                onChange={(e) => handleOnChange(PASSWORD)(e)}
                onBlur={(e) => handleOnLeave(PASSWORD)(e)}
                type={PASSWORD}
                variant="outlined"
                fullWidth
                label={reactIntl.formatMessage({
                  id: intl.firstTimeVisit('password-label'),
                })}
                helperText={reactIntl.formatMessage({
                  id: intl.firstTimeVisit('min-characters'),
                })}
                error={formErrorState.password}
              />
              <TextField
                value={formState.confirmPassword}
                onChange={(e) => handleOnChange(CONFIRM_PASSWORD)(e)}
                onBlur={(e) => handleOnLeave(CONFIRM_PASSWORD)(e)}
                type={PASSWORD}
                variant="outlined"
                fullWidth
                label={reactIntl.formatMessage({
                  id: intl.firstTimeVisit('confirm-password-label'),
                })}
                helperText={reactIntl.formatMessage({
                  id: intl.firstTimeVisit('must-match-password'),
                })}
                error={formErrorState.confirmPassword}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isFormValid}
                loading={isLoading}
                fullWidth
              >
                <FormattedMessage id={intl.firstTimeVisit('submit')} />
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </motion.div>
    </Dialog>
  )
}
