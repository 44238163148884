import { colors } from 'configs'
import { theme } from 'styles-new/mui5-transition/theme'

export const classDropdown = {
  background: theme.palette.grey[100],
  '& fieldset': {
    borderColor: theme.palette.grey[100],
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
    borderColor: 'transparent !important',
  },
}

export const classOutlineBox = {
  border: `2px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
}

export const classOutlineBoxTarget = {
  border: `2px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.grey[100],
  },
}

export const classCircularProgress = {
  padding: theme.spacing(2),
  textAlign: 'center',
}

export const classDialogTitle = {
  backgroundColor: colors?.risika,
  color: 'white',
}

export const classTooltip = {
  background: 'white',
  padding: theme.spacing(1),
  border: '1px solid lightgray',
  borderRadius: '4px',
}

export const classBreadcrumbsIcon = {
  mr: 0.5,
  color: colors.light_gray,
}

export const classBreadcrumbsText = {
  color: colors.light_gray,
}

export const standardIcon = {
  width: '20px',
  height: '20px',
  color: 'primary.700',
}
