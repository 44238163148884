import * as React from 'react'
import { Button as MUIButton, Box, CircularProgress, ButtonOwnProps } from '@mui/material'
import { styled } from '@mui/system'
import { emphasize, SxProps } from '@mui/material/styles'
import { colors } from 'configs'
import { default as cx } from 'classnames'

type ButtonProps = {
  className?: string
  children?: React.ReactNode
  color?: ButtonOwnProps['color']
  disabled?: boolean
  icon?: JSX.Element
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  loading?: boolean
  newFeatureDate?: Date
  size?: 'small' | 'medium' | 'large'
  text?: any | string
  variant?: 'contained' | 'outlined' | 'text'
  vertical?: boolean
  sx?: SxProps
}

const StyledButton = styled(MUIButton)(({ theme, color, variant }) => ({
  padding: theme.spacing(3, 4),
  fontSize: 16,
  lineHeight: 0,
  fontWeight: 500,
  textTransform: 'none',
  boxShadow: 'none',
  ...(variant === 'contained' && {
    backgroundColor: color ? theme.palette[color].main : colors?.risikaLight,
    color: color ? theme.palette[color].contrastText : theme.palette.text.primary,
    '&:hover': {
      backgroundColor: color
        ? emphasize(theme.palette[color].main, 0.2)
        : emphasize(colors?.risikaLight, 0.2),
    },
  }),
})) as typeof MUIButton

const StyledBox = styled(Box)(({ theme }) => ({
  fontSize: '1.2rem',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.error.main,
  padding: '0.5rem',
  borderRadius: '0.5rem',
  marginLeft: '1rem',
})) as typeof Box

const Label = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    children,
    color,
    disabled,
    icon,
    onClick,
    loading = false,
    newFeatureDate,
    size = 'medium',
    text,
    variant = 'contained',
    vertical = false,
    sx,
    ...other
  } = props

  let displayNewFeatureTag = false

  if (newFeatureDate) {
    const newFeatureDaysLimit = 60
    const currentDate = new Date()
    const timeDifference = currentDate.getTime() - newFeatureDate.getTime()
    const daysDifference = timeDifference / (1000 * 3600 * 24)
    if (daysDifference < newFeatureDaysLimit) displayNewFeatureTag = true
  }

  const allowedColors = ['primary', 'secondary', 'success', 'error', 'info', 'warning']

  return (
    <StyledButton
      ref={ref}
      color={allowedColors.includes(color!) ? color : undefined}
      disabled={disabled || loading}
      onClick={onClick}
      size={size}
      variant={variant}
      className={cx(className)}
      sx={sx}
      {...other}
    >
      <Label>
        {icon &&
          React.cloneElement(icon, {
            className: cx(vertical ? null : 'leftIcon'),
            style: { marginRight: vertical ? 0 : '1rem' },
          })}
        {children ?? text}
        {displayNewFeatureTag && <StyledBox>NEW!</StyledBox>}
        {loading && <CircularProgress size={20} />}
      </Label>
    </StyledButton>
  )
})

export default Button
