import * as React from 'react'
import { useState } from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import moment from 'moment'
import SwitchWithLabel from '../../../widgets/SwitchWithLabel'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PolicyCardStyles from './PolicyCard.styles'
import { PropsType } from './PolicyCard.types'
import FloatingActionMenu from '../../../widgets/FloatingActionMenu'
import { useCreditPolicyGetAll, useCreditPolicyUpdate } from 'services/queries'
import PolicyCardLoading from './PolicyCard.loading'
import { thereIsAnotherActivePolicyInThisCountry } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.controller'
import { AlreadyActivePolicyDialog } from 'pages/enterprise-credit-policy/widgets/Communication'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { CreditPolicyResponse, UseUserSelfResponse } from 'types/queries'
import { navigation } from 'services/navigation'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { ActionsProp } from 'pages/enterprise-credit-policy/widgets/FloatingActionMenu/FloatingActionMenu.types'
import { DK, SE, NO, FI } from 'country-flag-icons/react/3x2'

const { card, textInactive, containerLeft, containerRight } = PolicyCardStyles
const style = {
  height: '1.9rem',
  borderRadius: 2,
}

const flags = {
  DK: <DK data-cy={'policy-card-flag-DK'} style={style} />,
  SE: <SE data-cy={'policy-card-flag-SE'} style={style} />,
  NO: <NO data-cy={'policy-card-flag-NO'} style={style} />,
  FI: <FI data-cy={'policy-card-flag-FI'} style={style} />,
}

const adminFloatingActions = [
  'edit',
  'export',
  'duplicate',
  'edit-policy-settings',
  'delete',
] as ActionsProp[]
const userFloatingActions = ['export', 'edit-policy-settings'] as ActionsProp[]

const PolicyCard = ({ cardData, loading }: PropsType) => {
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData('userSelf') as UseUserSelfResponse
  const role = userData?.role
  const { name, description, updated, enabled, id, country, owner_name } = cardData
  const history = useHistory()
  // const policyStats = dummyDataPolicyStatsCards.slice(0, 2)
  const updateSingleCreditPolicyQuery = useCreditPolicyUpdate()
  const [otherActivePolicyName, setOtherActivePolicyName] = React.useState('')
  const { mutate: updateCreditPolicy, isLoading: isPolicyUpdating } =
    updateSingleCreditPolicyQuery
  const { data: allPolicyData } = useCreditPolicyGetAll()
  const [checked, setChecked] = useState(enabled)
  const [showMenu, setShowMenu] = useState(false)
  // const [expanded, setExpanded] = useState(false)
  const [showActiveDialog, setShowActiveDialog] = useState(false)
  const policyData = {
    description,
    name,
    country: cardData.country,
    enabled: !checked,
  }
  React.useEffect(() => {
    if (cardData.enabled !== checked) {
      setChecked(cardData.enabled)
    }
  }, [cardData.enabled, checked])

  const handleChangeStatus = () => {
    const otherActivePolicy = thereIsAnotherActivePolicyInThisCountry({
      actionedPolicyId: id,
      country: cardData.country,
      policies: allPolicyData?.policies,
    })
    if (!otherActivePolicy) {
      return updateCreditPolicy({ id, policyData })
    }
    setOtherActivePolicyName(otherActivePolicy.name)
    setShowActiveDialog(true)
  }
  const handleEdit = (policyData: CreditPolicyResponse) => {
    if (policyData) {
      const { country, name, enabled, id } = policyData
      history.push(
        navigation.creditPolicyBuilder({
          country,
          name,
          createActiveRule: enabled,
          policyId: id,
        })
      )
    }
  }
  // const handleExpand = () => setExpanded((prevState: Boolean) => !prevState)
  const handleToggleMenu = () => setShowMenu((prevState: Boolean) => !prevState)
  const handleMenuClickAway = () => setShowMenu(false)

  return isPolicyUpdating || loading ? (
    <PolicyCardLoading cardData={cardData} loading={true} />
  ) : (
    <>
      <Box data-cy={`policy-card-${id}`} key={id} sx={card}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack sx={containerLeft}>
            <Stack direction="row" alignItems="center" gap={3}>
              <Typography
                data-cy="policy-card-title"
                onClick={() => handleEdit(cardData)}
                sx={
                  !checked
                    ? { ...textInactive, cursor: 'pointer' }
                    : { cursor: 'pointer' }
                }
                variant="h5"
              >
                {decodeURIComponent(name)}
              </Typography>
              {flags[country as keyof typeof flags]}
              {/* <img
                data-cy={`policy-card-flag-${country}`}
                style={{ width: 22, height: '100%' }}
                src={flags[country as keyof typeof flags]}
                alt={country}
              /> */}
            </Stack>
            <Typography variant="body2" color="grey.700">
              <FormattedMessage
                id={intl.creditPolicyNew('policy-card-last-updated')}
                values={{
                  ownerId: owner_name,
                  date: moment(updated).format('DD/MM/YYYY'),
                }}
              />
            </Typography>
          </Stack>
          <Stack sx={containerRight} direction="row">
            <SwitchWithLabel
              disabled={role === 'user'}
              data-cy={`policy-card-status-switch${id}-${country}`}
              checked={checked}
              onChange={handleChangeStatus}
              labelChecked={
                <FormattedMessage id={intl.creditPolicyNew('policy-published')} />
              }
              labelUnChecked={
                <FormattedMessage id={intl.creditPolicyNew('policy-draft')} />
              }
            />
            <IconButton
              data-cy={`policy-card-actions-${id}-${country}`}
              onClick={handleToggleMenu}
              sx={{ fontSize: 24 }}
            >
              <MoreVertIcon sx={{ color: 'primary.main' }} fontSize="inherit" />
            </IconButton>
          </Stack>
        </Stack>
        {showMenu && (
          <FloatingActionMenu
            policyId={id}
            handleClickAway={handleMenuClickAway}
            actions={role === 'admin' ? adminFloatingActions : userFloatingActions}
          />
        )}
      </Box>
      <AlreadyActivePolicyDialog
        currentName={cardData.name}
        activeName={otherActivePolicyName}
        country={cardData.country}
        open={showActiveDialog}
        setOpen={setShowActiveDialog}
        action={() => updateCreditPolicy({ id, policyData })}
      />
    </>
  )
}

export default PolicyCard
