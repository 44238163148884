import { theme } from 'styles-new/mui5-transition/theme'
import { SxProps } from '@mui/system'

const triggerWidth = 40

const buttonStyle: SxProps = {
  height: theme.spacing(20),
  width: triggerWidth,
  borderRadius: '3px',
  padding: theme.spacing(1),
  backgroundColor: '#58BAC5',
  position: 'fixed',
  top: theme.spacing(21),
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 1201,
  overflow: 'hidden',
  '&:hover': {
    cursor: 'pointer',
  },
}

const iconContainer: SxProps = {
  height: theme.spacing(4),
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid #ffffff2b',
}

const iconStyle: SxProps = {
  color: '#fff',
  fontSize: '2rem',
}

const textContainer: SxProps = {
  width: theme.spacing(16),
  height: theme.spacing(16),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const textStyle: SxProps = {
  fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
  fontSize: '1.125rem',
  color: '#fff',
  transform: 'rotate(270deg)',
  fontWeight: 600,
}

const extraButtonStyles: SxProps = {
  // animation: `fadeInAnimation 850ms`,
  animation: `drawAnimation 550ms ease-out`,
  '@keyframes fadeInAnimation': {
    '0%': {
      opacity: 0,
    },
    '10%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes drawAnimation': {
    '0%': {
      opacity: 0.3,
      width: 0,
    },
    '100%': {
      opacity: 1,
      width: triggerWidth,
    },
  },
}

const extraButtonStylesSideDrawer: SxProps = {
  right: 0,
  left: -triggerWidth,
  position: 'absolute',
}

export const buttonClasses = {
  default: {
    buttonStyle: {
      ...buttonStyle,
      ...extraButtonStyles,
    },
    iconContainer,
    iconStyle,
    textContainer,
    textStyle,
  },
  sideDrawer: {
    buttonStyle: {
      ...buttonStyle,
      ...extraButtonStylesSideDrawer,
    },
    iconContainer,
    iconStyle,
    textContainer,
    textStyle,
  },
}
