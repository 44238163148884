import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { buttonClasses } from './DrawerTriggerButton.styles'
import IconButton from '@mui/material/IconButton'
import { PropTypes } from './DrawerTriggerButton.types'

const DrawerTriggerButton = ({ onClick, visible, variant, title, icon }: PropTypes) => {
  if (!visible) return null

  const classes = buttonClasses[variant]

  return (
    <>
      <Box data-cy="drawer-trigger-button" onClick={onClick} sx={classes.buttonStyle}>
        <Box sx={classes.iconContainer}>{icon(classes.iconStyle)}</Box>
        <Box sx={classes.textContainer}>
          <Typography variant="h6" sx={classes.textStyle}>
            {title}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default DrawerTriggerButton
