import { useEffect, useState } from 'react'
import { DateInput } from 'components-new'
import { Stack } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import { DateInputRangeProps } from './DateInputRange.types'
import styles from './DateInputRange.styles'

const DateInputRange = ({
  startDate,
  endDate,
  onChange,
  labelStart,
  labelEnd,
  renderInput,
  isSmallScreen = false,
  gap = 5,
  singleDateRangeField = false,
  containerStyles = {},
}: DateInputRangeProps) => {
  const [maxDate, setMaxDate] = useState<Date | null>(null)
  const [minDate, setMinDate] = useState<Date | null>(null)

  useEffect(() => {
    setMinDate(startDate)
    setMaxDate(endDate)
  }, [startDate, endDate])

  const handleStartDateChange = (date: Date | null) => {
    onChange(date, endDate)
  }
  const handleEndDateChange = (date: Date | null) => {
    onChange(startDate, date)
  }

  return (
    <Stack
      direction={isSmallScreen ? 'column' : 'row'}
      gap={gap}
      alignItems={singleDateRangeField ? 'center' : 'flex-end'}
      sx={{ ...styles.container(singleDateRangeField, containerStyles) }}
    >
      <DateInput
        value={startDate}
        onChange={handleStartDateChange}
        label={labelStart}
        maxDate={maxDate}
        dataCy="date-input-range-start"
        removeBorder={singleDateRangeField}
      />
      {singleDateRangeField && <RemoveIcon />}
      <DateInput
        value={endDate}
        onChange={handleEndDateChange}
        label={labelEnd}
        minDate={minDate}
        dataCy="date-input-range-end"
        removeBorder={singleDateRangeField}
      />
    </Stack>
  )
}

export default DateInputRange
