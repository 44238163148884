import { Link } from 'react-router-dom'
import { riskColors } from 'configs/constants/theme'
import { navigation } from 'services/navigation'
import { Box, Tooltip, Typography, Stack } from '@mui/material'
import { HumanDate } from 'components'
import Highlights from './Highlights'
import ConnectedBankruptcies from '../../MainRecommendation/components/CompanyHighlights/ConnectedBankruptcies'
import moment from 'moment'
import { removeDuplicates } from 'utils'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { formatToUniversalDate } from 'utils/formatDate'

const stripFunctions = (array, roles) => {
  return array?.map((person) => ({
    ...person,
    functions: person.functions.filter((x) => roles.includes(x.function)),
  }))
}

const handleProgressBar = (from) => {
  const monthsPassed = moment().diff(from, 'months')
  if (monthsPassed < 24) {
    return `linear-gradient(90deg, ${riskColors.high} ${monthsPassed}%, white ${monthsPassed}%)`
  }
  if (monthsPassed < 55) {
    return `linear-gradient(90deg, ${riskColors.medium} ${monthsPassed}%, white ${monthsPassed}%)`
  }
  if (monthsPassed >= 55) {
    return `linear-gradient(90deg, ${riskColors.low} ${monthsPassed}%, white ${monthsPassed}%)`
  }
}

const boardLast = (a, b) => {
  if (a.functionReal === 'BOARD OF DIRECTORS') return 1
  if (b.functionReal === 'BOARD OF DIRECTORS') return -1
  return 0
}

const getColor = (description) => {
  if (description === 'strong') return riskColors.low
  if (description === 'manual') return riskColors.medium
  if (description === 'weak') return riskColors.high
}

// type ManagementData = {|
//   name,
//   position,
//   from,
//   seniority,
//   otherPosition,
//   otherPositionsOrder,
//   highlights,
//   pep,
//   seniorityOrder,
//   highlightsOrder,
//   functionReal,
// |}

export const setManagementData = (
  managementRoles,
  data,
  connectedBancrupcies,
  classes,
  country
  // ): Array<ManagementData> => {
) => {
  // const hasRelationOverview = country?.toLowerCase() !== 'no'
  // const hasSeniority = country?.toLowerCase() !== 'no'
  // const hasOtherPositions = country?.toLowerCase() === 'dk'

  const personalBankruptcies = (bankruptciesData, personal_id) => {
    if (!bankruptciesData) return []
    const personalBankruptcies = bankruptciesData?.find((item) => {
      return item.personal_id === personal_id.toString()
    })
    return personalBankruptcies ? [personalBankruptcies] : []
  }

  const returnValue = []
  stripFunctions(removeDuplicates(data, 'personal_id'), managementRoles)
    ?.filter((x) => x.functions.length)
    ?.forEach((person) => {
      const bankruptcies = personalBankruptcies(connectedBancrupcies, person.personal_id)
      person?.functions?.forEach((func) => {
        returnValue.push({
          name: (
            <Link
              to={navigation.relationOverview({
                id: person.personal_id,
                country,
              })}
            >
              <Typography variant="link2">{person?.name}</Typography>
            </Link>
          ),
          position: <Typography variant="body2">{func?.title}</Typography>,
          from: (
            <Typography variant="body2">
              {formatToUniversalDate(func?.valid_from)}
            </Typography>
          ),
          seniority: func?.valid_from ? (
            <div>
              <Typography variant="body2">
                <HumanDate date={moment(func?.valid_from)} format="duration" />
              </Typography>
              <div
                className={classes.progressBar}
                style={{
                  background: handleProgressBar(moment(func?.valid_from)),
                }}
              ></div>
            </div>
          ) : (
            '-'
          ),
          // Change the 2 to a actual variable when you get the data
          otherPosition:
            // hasOtherPositions &&
            person?.active_company_relations?.length - 1 > 0 ? (
              <Link
                to={navigation.relationOverview({
                  id: person.personal_id,
                  country,
                })}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="link2" sx={{ textDecoration: 'underline' }}>
                    {person.active_company_relations.length - 1}
                    <FormattedMessage id={intl.companyRoles('other-positions')} />
                  </Typography>
                </Stack>
              </Link>
            ) : (
              <Box textAlign="center">-</Box>
            ),
          otherPositionsOrder: person?.active_company_relations?.length
            ? person?.active_company_relations?.length
            : 0,
          highlights: bankruptcies.length ? (
            <Highlights
              content={(open, onClose) => (
                <ConnectedBankruptcies
                  open={open}
                  onClose={onClose}
                  title={
                    <FormattedMessage
                      id={intl.companyRoles('analysis-of-bankruptcies')}
                    />
                  }
                  data={bankruptcies}
                />
              )}
            />
          ) : (
            <Typography variant="body2" style={{ paddingLeft: '2rem' }}>
              -
            </Typography>
          ),
          pep: person?.politically_exposed_persons ? (
            <>
              <Tooltip
                title={person?.politically_exposed_persons.description}
                placement="top"
              >
                <Typography
                  style={{
                    color: getColor(person?.politically_exposed_persons.description_code),
                    cursor: 'default',
                  }}
                >
                  PEP
                </Typography>
              </Tooltip>
            </>
          ) : (
            '-'
          ),
          seniorityOrder: formatToUniversalDate(func?.valid_from),
          highlightsOrder: bankruptcies?.length ?? 0,
          functionReal: func?.function,
        })
      })
    })
  return returnValue.sort((a, b) => boardLast(a, b))
}
