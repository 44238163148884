// @flow
import {
  Card,
  CardContent,
  Checkbox,
  CardHeader,
  Box,
  Typography,
  Stack,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { navigation } from 'services/navigation'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useCreditCheck } from 'pages/credit-check/context'
import { useFinancialStats } from 'services/queries'
import { take } from 'ramda'
import Capitalize from 'lodash/capitalize'
import PropertyWrapper from 'pages/credit-check/scenes/PropertyWrapper'

const styles = {
  checkbox: {
    padding: 0,
    cursor: 'default',
  },
}

const AuditorInfo = () => {
  const { localId } = useCreditCheck()
  const financialStatsQuery = useFinancialStats(localId)

  if (financialStatsQuery.isLoading) {
    return <div>Loading...</div>
  }

  const data = take(4, financialStatsQuery.data ?? [])
  return (
    <Stack direction="row" data-cy="auditor-info" spacing={4}>
      {data.map((x, i) => (
        <Card style={{ flex: 1 }} sx={{ boxShadow: 2, paddingBottom: 4 }} key={i}>
          <CardHeader
            disableTypography
            title={
              <Typography variant="subtitle1">
                {`${moment(x.period.start).format('DD.MM.YYYY')} -
                            ${moment(x.period.end).format('DD.MM.YYYY')}`}
              </Typography>
            }
          />
          <CardContent
            sx={{
              '&.MuiCardContent-root:last-child': { paddingBottom: 4 },
            }}
          >
            <Stack direction="column" spacing={2}>
              <PropertyWrapper titleIntlId={intl.annualReportInformation('approvalDate')}>
                {x.approval_date && moment(x.approval_date).format('DD.MM.YYYY')}
              </PropertyWrapper>

              <PropertyWrapper
                titleIntlId={intl.annualReportInformation('classOfReportingEntity')}
              >
                {x.class_of_reporting_entity}
              </PropertyWrapper>

              <PropertyWrapper titleIntlId={intl.annualReportInformation('ifrs')}>
                {
                  <Stack direction="row" alignItems="center">
                    <Box>
                      <Typography variant="body2">
                        {x.ifrs ? (
                          <FormattedMessage id={intl.generic('yes')} />
                        ) : (
                          <FormattedMessage id={intl.generic('no')} />
                        )}
                      </Typography>
                    </Box>
                    <Checkbox
                      className={styles.checkbox}
                      indeterminate={!x.ifrs}
                      checked={x.ifrs}
                      value="checkedB"
                      style={x.ifrs ? { color: 'green' } : { color: 'red' }}
                    />
                  </Stack>
                }
              </PropertyWrapper>

              <PropertyWrapper titleIntlId={intl.annualReportInformation('name')}>
                {x.auditor.name}
              </PropertyWrapper>

              <PropertyWrapper titleIntlId={intl.annualReportInformation('description')}>
                {x.auditor.description}
              </PropertyWrapper>

              <PropertyWrapper
                titleIntlId={intl.annualReportInformation('type_of_assistance')}
              >
                {x.auditor.type_of_assistance}
              </PropertyWrapper>

              <PropertyWrapper titleIntlId={intl.annualReportInformation('company_name')}>
                {x.auditor.company_name && (
                  <Link to={navigation.creditCheck(x.auditor.local_organization_id)}>
                    <Typography variant="body2">
                      {Capitalize(x.auditor.company_name)}
                    </Typography>
                  </Link>
                )}
              </PropertyWrapper>
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>
  )
}

export default AuditorInfo
