import { connect } from 'react-redux'
import { HistoryHeader } from 'components'
import TextBlockHandler from './scenes/TextBlockHandler'
import TableHandler from './scenes/TableHandler'
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  useMediaQuery,
  Box,
  Stack,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import RisikaScore from './information/RisikaScore.json'
import RiskTable from './information/RiskTable.json'
import PerformanceTable from './information/PerformanceTable.json'
import UnscoredCompaniesText from './information/unscoredCompanies/UnscoredCompaniesText.json'
import UnscoredCompaniesTable from './information/unscoredCompanies/UnscoredCompaniesTable.json'
import CreditMaximum from './information/CreditMaximum.json'
import CreditDays from './information/CreditDays.json'
import ConnectedBancrupcies from './information/ConnectedBancrupcies.json'
import HowText from './information/keyFiguresPerformance/HowText.json'
import HowTable from './information/keyFiguresPerformance/HowTable.json'
import Why from './information/keyFiguresPerformance/Why.json'
import SoleProprietorshipModel from './information/SoleProprietorshipModel.json'
import What from './information/ownership/What.json'
import LevelOfAuditText from './information/ownership/LevelOfAuditText.json'
import LevelOfAuditTable from './information/ownership/LevelOfAuditTable.json'
import WhatTable from './information/numberOfEmployees/WhatTable.json'
import WhatText from './information/numberOfEmployees/WhatText.json'
import ProfitabilityText from './information/financialKeyFigures/ProfitabilityText.json'
import LiquidityText from './information/financialKeyFigures/LiquidityText.json'
import DebtRatiosText from './information/financialKeyFigures/DebtRatiosText.json'
import ProfitabilityTable from './information/financialKeyFigures/ProfitabilityTable.json'
import LiquidityTable from './information/financialKeyFigures/LiquidityTable.json'
import DebtRatiosTable from './information/financialKeyFigures/DebtRatiosTable.json'
import OtherRatiosTable from './information/financialKeyFigures/OtherRatiosTable.json'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import styles from './styles/faq'

const { sectionContainer, subSectionContainer, customTypographyStyles } = styles

const FaqPage = (props) => {
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isLessThan800 = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  let language = props.language

  if (language !== 'da_DK' && language !== 'en_UK' && language !== 'nb_NO') {
    language = 'en_UK'
  }

  const handleTableWidth = (width) => {
    return isLessThan800 ? '100%' : width
  }

  return (
    <Stack>
      <HistoryHeader title="FAQ" historyKey={'faq'} location={'faq'} />
      <Stack gap={6} py={8} mt={2} px="170px" sx={{ backgroundColor: 'common.white' }}>
        <Typography variant="h1">
          <FormattedMessage id={intl.faq('Understanding risika score and figures')} />
        </Typography>
        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Risika score')} />
          </Typography>
          <TextBlockHandler data={RisikaScore} />
          <TableHandler
            isPhone={isPhone}
            titles={['Risika score', 'Risk']}
            data={RiskTable}
          />
        </Stack>

        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Performance')} />
          </Typography>
          <TableHandler
            width={handleTableWidth('36%')}
            isPhone={isPhone}
            titles={['Risika score', 'Distress percentage year']}
            data={PerformanceTable}
          />
          <Typography>
            {<FormattedMessage id={intl.faq('Prevalence: 3.75%')} />}
          </Typography>
        </Stack>

        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Unscored companies')} />
          </Typography>
          <Box pb={6}>
            <Typography variant="h4">
              <FormattedMessage id={intl.faq('Why')} />
            </Typography>
            <Typography variant="body1">{UnscoredCompaniesText[0][language]}</Typography>
            <TableHandler
              isPhone={isPhone}
              titles={['Reason', 'Explanation']}
              data={UnscoredCompaniesTable[language]}
            />
          </Box>
        </Stack>

        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Credit maximum')} />
          </Typography>
          <TextBlockHandler data={CreditMaximum} removeBoldTags />
        </Stack>

        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Credit days')} />
          </Typography>
          <TextBlockHandler data={CreditDays} />

          <Box>
            <Typography variant="h4">
              <FormattedMessage id={intl.faq('Connected bankruptcies')} />
            </Typography>
            <TextBlockHandler data={ConnectedBancrupcies} />
          </Box>
        </Stack>

        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Key figure performance')} />
          </Typography>

          <Box>
            <Typography variant="h4">
              {<FormattedMessage id={intl.faq('Why')} />}
            </Typography>
            <Typography>{Why[language]?.title}</Typography>
            <List>
              {Why[language]?.list.map((listText) => (
                <ListItem sx={{ paddingY: 1, paddingX: 5 }} key={listText}>
                  <ListItemIcon sx={{ minWidth: 24 }}>
                    <FontAwesomeIcon icon={faCircle} size="xs" />
                  </ListItemIcon>
                  <Typography variant="body1">{listText}</Typography>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box>
            <Typography variant="h4">
              {<FormattedMessage id={intl.faq('How')} />}
            </Typography>
            <TableHandler
              width={handleTableWidth('36%')}
              isPhone={isPhone}
              titles={['Performance', 'Fraktil']}
              data={HowTable}
            />
          </Box>
          <TextBlockHandler data={HowText} />
        </Stack>

        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Sole proprietorship model')} />
          </Typography>
          <TextBlockHandler data={SoleProprietorshipModel} />
        </Stack>

        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Ownership')} />
          </Typography>
          <TextBlockHandler data={What} removeBoldTags addNewLine />
        </Stack>

        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Level of audit')} />
          </Typography>
          {/* <TableHandler */}
          {/*  isPhone={isPhone} */}
          {/*  titles={['Level of audit', 'Erklæringstype', 'Effect']} */}
          {/*  data={LevelOfAuditTable[language]} */}
          {/* /> */}
          <TextBlockHandler data={LevelOfAuditText} removeBoldTags addNewLine />
        </Stack>

        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Number of employees')} />
          </Typography>
          <Box>
            <Typography variant="h4">
              {<FormattedMessage id={intl.faq('What')} />}
            </Typography>
            <TextBlockHandler data={WhatText} removeBoldTags />
          </Box>
          <Box pb={6}>
            <TableHandler
              width={handleTableWidth('36%')}
              isPhone={isPhone}
              titles={['number_of_employees', 'company_size']}
              data={WhatTable[language]}
            />
          </Box>
        </Stack>

        <Stack sx={sectionContainer}>
          <Typography variant="h2">
            <FormattedMessage id={intl.faq('Financial key figures')} />
          </Typography>
          <Stack sx={subSectionContainer}>
            <TextBlockHandler data={ProfitabilityText} removeBoldTags />
            <TableHandler
              width={handleTableWidth('50%')}
              isPhone={isPhone}
              titles={['variable', 'definition']}
              data={ProfitabilityTable[language]}
            />
          </Stack>
          <Stack sx={subSectionContainer}>
            <TextBlockHandler data={LiquidityText} removeBoldTags />
            <TableHandler
              width={handleTableWidth('50%')}
              isPhone={isPhone}
              titles={['variable', 'definition']}
              data={LiquidityTable[language]}
            />
          </Stack>
          <Stack sx={subSectionContainer}>
            <TextBlockHandler data={DebtRatiosText} removeBoldTags />
            <TableHandler
              width={handleTableWidth('50%')}
              isPhone={isPhone}
              titles={['variable', 'definition']}
              data={DebtRatiosTable[language]}
            />
          </Stack>
          <Typography sx={customTypographyStyles}>
            <FormattedMessage id={intl.faq('Other Ratios')} />
          </Typography>
          <TableHandler
            width={handleTableWidth('50%')}
            isPhone={isPhone}
            titles={['variable', 'definition']}
            data={OtherRatiosTable[language]}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

const mapStateToProps = (state) => ({
  language: state.auth.user.local.lang,
})

export default connect(mapStateToProps)(FaqPage)
