import React from 'react'
import { Stack } from '@mui/material'
import { InputField } from 'components-new'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import { PAYMENT_REMARKS } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import ProTips from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/widgets/ActionPanel/widgets/ProTips/ProTips'

const AverageRepaymentDurationInput = ({ isAdvanced = true }) => {
  const { policy, setPolicy, isInferiorPlan } = useCountryPolicy()
  const defVal = policy.payment_remarks_max_repayment_time ?? 0
  const [val, setVal] = React.useState<number>(defVal)

  // this is needed to set the initial value after it's been null
  React.useEffect(() => {
    if (typeof policy.payment_remarks_max_repayment_time === 'number') {
      setVal(policy.payment_remarks_max_repayment_time)
    }
  }, [policy.payment_remarks_max_repayment_time])

  return (
    <Stack spacing={4}>
      <InputField
        value={val}
        addOn={'Days'}
        addOnPosition="end"
        handleChange={function (value: string): void {
          if (isNaN(Number(value))) return
          setVal(Number(value))
          setPolicy({ ...policy, payment_remarks_max_repayment_time: Number(value) })
        }}
        disabled={isAdvanced && isInferiorPlan}
      />
      <ProTips tip={PAYMENT_REMARKS} />
    </Stack>
  )
}

export default AverageRepaymentDurationInput
