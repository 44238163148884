import * as React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { Typography, TextField, Divider, Box, CircularProgress } from '@mui/material'
import { Button, Hide } from 'components'
import ButtonMenu from 'components/ui/ButtonMenu'
import ImportDropzone from './ImportDropzone'
import FieldList from './FieldList'
import { FormattedMessage, MessageFormatElement } from 'react-intl'
import intl from 'localization/components'
import { useSelector } from 'react-redux'
import { inferLocalId } from 'services/company'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { downloadAs } from 'services/export'
import { useIntl } from 'react-intl'
import { validateLocalIds } from 'services/api'
import { useAccountStatus } from 'services/queries'
import ConditionallyRender from '../ui/ConditionallyRender'
import { colors } from 'configs'
import { ReduxRootState } from 'store_deprecated/types'
import { Icon } from '@fortawesome/fontawesome-svg-core'
import { useDebounce } from 'react-use'
import SelectCountryForCompany from './SelectCountryForCompany'
import { convertToFinnishId } from 'utils/convertToFinnishId'

type ImportCompaniesFromFileProps = {
  onSuccess: (
    importData: Company[],
    interalIds: { internalId: string; localId: string; name?: string }[]
  ) => void
  listName?: string
  hideManualEntry?: boolean
  importValues: any
  setImportValues: any
}

export type Company = {
  country: string
  id: string
  name?: string
}

type SupportedFormats = 'csv' | 'excel' | 'json'

const ImportCompaniesFromFile = (props: ImportCompaniesFromFileProps) => {
  const {
    hideManualEntry = false,
    onSuccess,
    listName,
    importValues,
    setImportValues,
  } = props
  const lists = useSelector(
    (state: ReduxRootState) => state.risika.newRiskMonitoring.lists
  )
  const selectedList = useSelector(
    (state: ReduxRootState) => state.risika.newRiskMonitoring.selectedList
  )
  const accountStatusQuery = useAccountStatus()
  const allowedCountries = accountStatusQuery.data?.rights?.allow_countries

  const [isValidating, setIsValidating] = React.useState(false)
  const [fieldList, setFieldList] = useState<string[][]>([])
  const [selectedField, setSelectedField] = useState<string>('')
  const [isSelectingInternalIdField, setIsSelectingInternalIdField] = useState(false)
  const [faultyValues, setFaultyValues] = useState<{ id: string }[]>([])
  const [duplicateValues, setDuplicateValues] = useState([])
  const [textFieldValue, setTextFieldValue] = useState('')
  const [manualInputValue, setManualInputValue] = useState('')
  const [tempCompanyOptions, setTempCompanyOptions] = useState<Record<number, string>>({})
  const [countryDuplicates, setCountryDuplicates] = React.useState<
    Record<string, Company[]>
  >({})

  const applySelectedCountries = () => {
    const countriesToImport: Company[] = []
    Object.keys(tempCompanyOptions).map((companyId) => {
      const countryCode = tempCompanyOptions?.[+companyId]
      const formattedCompanyId =
        countryCode === 'FI' ? convertToFinnishId(companyId) : companyId

      countriesToImport.push({
        id: formattedCompanyId,
        country: countryCode || '',
      })

      setImportValues([...importValues, ...countriesToImport])
      setCountryDuplicates({})
    })
  }

  const handleIdValidation = (localIds: string[]) =>
    validateLocalIds({
      localIds,
      allowedCountries,
    })
      .then(({ data }) => {
        const keys = Object.keys(data)
        setCountryDuplicates(findValidInMultipleCountries(data, keys))
        setFaultyValues(findValidInNoCountries(data, keys))
        setImportValues(findValidInOneCountry(data, keys))
        setIsValidating(false)
      })
      .catch((err) => {
        console.log('err', err)
      })

  useDebounce(
    () => {
      const rawLocalIds = sanitizeIdList(manualInputValue.split(/[\s,.]+/))
      const uniqueRawLocalIds = [...new Set(rawLocalIds)]
      handleIdValidation(uniqueRawLocalIds)
    },
    400,
    [manualInputValue]
  )
  const [internalIds, setInternalIds] = useState<
    { internalId: string; localId: string }[]
  >([])

  const reactIntl = useIntl()
  const messages = reactIntl.messages
  const faultyIdsButtonLabel =
    messages[intl.riskMonitoringNew('faulty-ids-button-export-label')]
  const faultyIdsExportFileName = messages[intl.riskMonitoringNew('faulty-ids-file-name')]
  const duplicateIdsButtonLabel =
    messages[intl.riskMonitoringNew('duplicate-ids-button-export-label')]
  const duplicateIdsExportFileName =
    messages[intl.riskMonitoringNew('duplicate-ids-file-name')]

  useEffect(() => {
    onSuccess(importValues, internalIds)
  }, [importValues, internalIds, onSuccess])

  const createButtonMenu = (
    label: string | MessageFormatElement[],
    fileName: string | MessageFormatElement[],
    values: { id: string }[]
  ) => {
    return {
      label,
      menuItems: [
        { label: 'CSV', action: () => exportData(fileName, 'csv', values) },
        {
          label: 'Excel',
          action: () => exportData(fileName, 'excel', values),
        },
      ],
    }
  }

  const buttonMenuExportFaultyIds = createButtonMenu(
    faultyIdsButtonLabel,
    faultyIdsExportFileName,
    faultyValues
  )

  const buttonMenuExportDuplicateIds = createButtonMenu(
    duplicateIdsButtonLabel,
    duplicateIdsExportFileName,
    duplicateValues
  )
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setManualInputValue(value)
    setTextFieldValue(value)
    const rawLocalIds = sanitizeIdList(value.split(/[\s,.]+/))
    const uniqueRawLocalIds = [...new Set<string>(rawLocalIds)]
    const localIds = uniqueRawLocalIds.map(inferLocalId).filter((x) => x != null)
    setImportValues(localIds)
    setDuplicateValues(getDuplicateIds(rawLocalIds))
    setFaultyValues(getFaultyIds(uniqueRawLocalIds, localIds as Record<string, string>[]))
    // Log all states bellow
  }

  // type coming from external library
  const handleSetFieldList = useCallback((rows: any) => {
    setSelectedField('')
    setFieldList(rows ?? [])
  }, [])

  const findValidInMultipleCountries = (
    matches: Record<string, any[]>,
    keys: string[]
  ) => {
    const doubleKeys = keys.filter((key) => matches[key]?.length > 1)
    if (doubleKeys.length) {
      const arrays = doubleKeys.map((key) => matches[key])
      let object = {}
      arrays.forEach((array) => {
        object = {
          ...object,
          [array[0].id]: array,
        }
      })
      return object
    }
    return {}
  }
  const findValidInNoCountries = (matches: Record<string, unknown[]>, keys: string[]) => {
    return keys
      .filter((key) => matches[key]?.length === 0)
      ?.map((x) => {
        return {
          id: x,
        }
      })
  }
  const findValidInOneCountry = (matches: Record<string, any[]>, keys: string[]) => {
    const remainingKeys = keys.filter((key) => matches[key]?.length === 1)
    return remainingKeys.map((key) => ({
      id: matches[key][0].country === 'SE' ? matches[key][0].hash : matches[key][0].id,
      country: matches[key][0].country,
    }))
  }
  const handleSetSelectedField = (field: string) => {
    setSelectedField(field)
    const list = fieldList.map((row) => row[+field])
    const uniqueRawLocalIds = [...new Set(list)].filter((item) => item)
    handleIdValidation(uniqueRawLocalIds)
    setDuplicateValues(getDuplicateIds(list))
  }

  const handleSetInternalIds = (field: string) => {
    setIsSelectingInternalIdField(false)
    const combinedIds = fieldList.map((row) => ({
      internalId: row[+field] as string,
      localId: inferLocalId(row[+selectedField]),
    }))

    setInternalIds(
      combinedIds as React.SetStateAction<{ internalId: string; localId: string }[]>
    )
  }

  useEffect(() => {
    if (Object.keys(countryDuplicates).length) {
      const initialData = Object.keys(countryDuplicates).reduce((acc, curr) => {
        acc = { ...acc, [curr]: 'DK' }
        return acc
      }, {} as Record<number, string>)

      setTempCompanyOptions(initialData)
    }
  }, [setTempCompanyOptions, countryDuplicates])
  /**
   * This function sanitize list of IDs, so it could be properly processed by
   * the API
   *
   * @param {Array} idList The list of company Ids.
   * @return {Array} The list of "sanitized" company Ids.
   */
  const sanitizeIdList = (idList: string[]) => {
    return idList.map((id) => {
      // Replace all the letters
      let sanitizedId = id.replaceAll(/[A-Za-z$-]/g, '')
      // Strip "01" addition on Swedish IDs
      if (sanitizedId.length === 12 && sanitizedId.slice(-2) === '01') {
        sanitizedId = sanitizedId.slice(0, -2)
      }
      return sanitizedId
    })
  }

  /**
   * Returns list (Array) of the duplicate company Ids.
   *
   * @param {Array} idList The list of all (not validated) company Ids.
   * @return {Array} The list of duplicate Ids.
   */
  const getDuplicateIds = (idList: any[]) => {
    const duplicates = idList.reduce((acc, el, i, arr) => {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) {
        acc.push({ id: el })
      }
      return acc
    }, [])
    return duplicates
  }

  /**
   * Returns list (Array of Objects) of the faulty company Ids.
   * Iterate through validatedIdList and remove one by one Company objects from fullIdList.
   * What remains is the wanted list of invalid companies
   *
   * @param {Array of Objects} fullIdList The list of all (not validated) company Ids.
   * @param {Array of Objects} validatedIdList The list of validated company Ids.
   * @return {Array of Objects} The list of "faulty" Ids.
   */
  const getFaultyIds = (
    fullIdList: string[],
    validatedIdList: Record<string, string>[]
  ) => {
    const clonedFullIdList = fullIdList.map((id) => {
      return { id }
    })
    validatedIdList.forEach((validEl) => {
      const indexToBeRemoved = clonedFullIdList.findIndex(
        (clonedFullIdListEl) => clonedFullIdListEl.id === validEl.id
      )
      if (indexToBeRemoved !== -1) {
        clonedFullIdList.splice(indexToBeRemoved, 1)
      }
    })
    return clonedFullIdList
  }
  /**
   * Export (start download file procedure in the browser) data in csv or excel file format.
   *
   * @param {string} name File name.
   * @param {string} format File format (extension).
   * @param {Object} data Data object to be exported in table.
   */
  function exportData(
    name: string | MessageFormatElement[],
    format: SupportedFormats,
    data: { id: string }[]
  ) {
    const download = downloadAs(format)!
    download(name, data)
  }

  if (accountStatusQuery.isLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Hide when={textFieldValue !== ''}>
        <Typography variant="subtitle2" gutterBottom mt={4} mb={1}>
          <FormattedMessage id={intl.riskMonitoring('import-from-file')} />
        </Typography>
        <ImportDropzone
          onFileLoaded={handleSetFieldList}
          secondaryText={'import-company-secondary'}
        />
        <Hide when={!!selectedField || !fieldList?.length}>
          <Typography variant="h6">
            <FormattedMessage
              id={intl.riskMonitoring('import-from-file-company-id-field')}
            />
          </Typography>
          <FieldList
            fields={fieldList?.[0] || null}
            onSelectField={handleSetSelectedField}
          />
        </Hide>
        <Hide when={!selectedField || internalIds.length > 0}>
          <Hide when={isSelectingInternalIdField}>
            <Button
              color="primary"
              icon={<FontAwesomeIcon icon={faPlus as Icon} />}
              onClick={() => setIsSelectingInternalIdField((isSelecting) => !isSelecting)}
            >
              {' '}
              <FormattedMessage
                id={intl.riskMonitoring('import-from-file-include-internal-id')}
              />
            </Button>
            <Box my={2}>
              <Divider />
            </Box>
          </Hide>
          <Hide when={!isSelectingInternalIdField}>
            <Typography variant="h6">
              <FormattedMessage
                id={intl.riskMonitoring('import-from-file-internal-id-field')}
              />
            </Typography>
            <FieldList fields={fieldList?.[0]} onSelectField={handleSetInternalIds} />
          </Hide>
        </Hide>
      </Hide>
      <Hide when={Boolean(fieldList?.length) || hideManualEntry}>
        <Typography variant="subtitle1" gutterBottom mt={4}>
          <FormattedMessage id={intl.riskMonitoring('import-from-manual-entry')} />
        </Typography>
        <TextField
          data-cy="import-monitoring-input"
          label={
            <Typography variant="body2">
              <FormattedMessage id={intl.riskMonitoringNew('cvt-textfield-label')} />
            </Typography>
          }
          value={textFieldValue}
          onChange={handleTextFieldChange}
          variant="outlined"
          fullWidth
          multiline
          maxRows={4}
          minRows={2}
        />
        <Typography variant="caption">
          <FormattedMessage
            id={intl.riskMonitoring('import-from-manual-entry-helper-text')}
          />
        </Typography>
      </Hide>
      <ConditionallyRender
        condition={isValidating}
        when={<CircularProgress style={{ color: colors.risikaContrast }} />}
        otherwise={
          <>
            <Hide when={!selectedField && textFieldValue === ''}>
              <Typography>
                <FormattedMessage
                  id={intl.riskMonitoringNew('sum-of-all-ids-found')}
                  values={{
                    count:
                      importValues.length +
                      duplicateValues.length +
                      faultyValues.length +
                      Object.keys(countryDuplicates ?? {})?.length,
                  }}
                />
              </Typography>
              <Hide when={!duplicateValues.length}>
                <Box mt={2}>
                  <Typography>
                    <FormattedMessage
                      id={intl.riskMonitoringNew('duplicate-ids-found-message')}
                      values={{
                        count: duplicateValues.length,
                      }}
                    />
                  </Typography>
                </Box>
                <Box mt={2}>
                  <ButtonMenu menuData={buttonMenuExportDuplicateIds} />
                </Box>
              </Hide>
              <Hide when={!faultyValues.length}>
                <Box mt={4}>
                  <Typography>
                    <FormattedMessage
                      id={intl.riskMonitoringNew('faulty-ids-found-message')}
                      values={{
                        count: faultyValues.length,
                      }}
                    />
                  </Typography>
                </Box>
                <Box mt={2}>
                  <ButtonMenu menuData={buttonMenuExportFaultyIds} />
                </Box>
              </Hide>
              <Hide when={!Object.keys(countryDuplicates).length}>
                <Box mt={2}>
                  <Typography data-cy="duplicate-countries-found-text">
                    <FormattedMessage
                      id={intl.riskMonitoringNew('duplicate-countries-found-message')}
                      values={{
                        count: Object.keys(countryDuplicates).length,
                      }}
                    />
                  </Typography>
                  <SelectCountryForCompany
                    data={countryDuplicates}
                    tempCompanyOptions={tempCompanyOptions}
                    setTempCompanyOptions={setTempCompanyOptions}
                    onConfirmSelection={applySelectedCountries}
                  />
                </Box>
              </Hide>
              <Box my={2}>
                <Divider />
              </Box>
              <Box mt={2}>
                <Typography>
                  <FormattedMessage
                    id={intl.riskMonitoringNew('add-to-monitoring-counter')}
                    values={{
                      count: importValues.length,
                      listName: listName || lists[selectedList]?.name,
                    }}
                  />
                </Typography>
              </Box>
            </Hide>
          </>
        }
      />
    </>
  )
}

export default ImportCompaniesFromFile
