import React, { useState } from 'react'
import { take, isNil } from 'ramda'
import { makeStyles } from '@mui/styles'
import { Typography, Box, useMediaQuery } from '@mui/material'
import { default as cx } from 'classnames'
import { Stack, Sidescroll, ConditionallyRender, ViewSwitch, Show } from 'components'

import AuditorInfo from './AuditorInfo'
import { TableRowValue } from './TableRowValue'
import { TableRowTitle } from './TableRowTitle'
import { AnimatedTableRow } from './AnimatedTableRow'
import { AnnualReport } from './AnnualReport'

// import { FormattedDate, FormattedMessage } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useCreditCheck } from 'pages/credit-check/context'
import {
  useFinancialNumbers,
  useFinancialNumbersConsolidated,
  useFinancialStats,
  useFinancialStatsConsolidated,
  useUserSettings,
} from 'services/queries'
import { getFinancialFigures } from './helpers'
import { formatToUniversalDate } from 'utils/formatDate'
import KeyFinancialFiguresSkeleton from './KeyFinancialFigures.skeleton'
import { LoadingSkeleton } from 'components-new'

const useStyles = makeStyles((theme) => ({
  th: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '4rem',
    },
  },
  textRight: {
    textAlign: 'right',
  },
}))
const REPORT_COUNT_LIMIT = 4

const KeyFinancialFigures = ({ isShowMore, setIsConsolidatedParent }) => {
  const classes = useStyles()
  const isSmallDisplay = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const userSettingsQuery = useUserSettings()

  const [appendCurrencyURL, setAppendCurrencyURL] = useState(true)

  const { localId } = useCreditCheck()
  const financialNumbersQuery = useFinancialNumbers(localId, {}, appendCurrencyURL)
  const financialNumbersConsolidatedQuery = useFinancialNumbersConsolidated(localId)

  const financialStatsQuery = useFinancialStats(localId)
  const financialStatsConsolidatedQuery = useFinancialStatsConsolidated(localId)

  // TODO: make sure we match them with the financial stats (i.e. remove "old" entries)
  const [showConsolidated, setShowConsolidated] = React.useState(
    isNil(financialNumbersQuery.data) &&
      financialNumbersQuery.data?.length &&
      ['DK', 'NO', 'SE', 'FI'].includes(localId.country)
  )

  if (!financialNumbersConsolidatedQuery?.data?.length && showConsolidated) {
    setShowConsolidated(false)
    setIsConsolidatedParent(false)
  }

  const isLoading =
    financialNumbersQuery.isLoading ||
    financialStatsQuery.isLoading ||
    financialStatsConsolidatedQuery.isLoading ||
    financialNumbersConsolidatedQuery.isLoading ||
    userSettingsQuery.isLoading

  const financialStatsToShow = take(
    REPORT_COUNT_LIMIT,
    showConsolidated
      ? financialStatsConsolidatedQuery.data ?? []
      : financialStatsQuery?.data ?? [] // We need to be able to decide which statement to use.
  )

  const financialNumbersToShow = take(
    REPORT_COUNT_LIMIT,
    showConsolidated
      ? financialNumbersConsolidatedQuery?.data ?? []
      : financialNumbersQuery.data ?? [] // We need to be able to decide which statement to use.
  )

  const getFinancialNumbers = (property) => {
    return financialNumbersToShow?.map((x) => x[property])
  }

  const getFinancialStats = (property) => {
    return financialStatsToShow?.map((x) => x[property])
  }

  const annualReportLinks = getFinancialStats('pdf_link')
  const currency = getFinancialStats('currency')
  const userCurrency = userSettingsQuery?.data?.selectedCurrency?.currency
  const periods = getFinancialNumbers('period')
  const keyFinancialFigures = getFinancialFigures(getFinancialNumbers)

  const hasConsolidatedStatement = Boolean(financialNumbersConsolidatedQuery.data?.length)

  const handleActionChange = () => {
    setShowConsolidated(!showConsolidated)
    setIsConsolidatedParent(!showConsolidated)
  }

  return (
    <LoadingSkeleton
      SkeletonComponent={KeyFinancialFiguresSkeleton}
      isLoading={isLoading}
    >
      <Stack
        direction="column"
        spacing={2}
        style={{
          overflow: 'hidden',
          flexGrow: 0,
          padding: '20px 20px 0 20px',
          marginBottom: '16px',
        }}
        flex="1"
      >
        <Box display={'flex'} flex={1} justifyContent={'space-between'}>
          <Show when={hasConsolidatedStatement}>
            <ViewSwitch
              actionChange={handleActionChange}
              checkedVar={!showConsolidated}
              textOff={
                <FormattedMessage
                  id={intl.keyFinancialFigures('consolidated-statement')}
                />
              }
              textOn={
                <FormattedMessage id={intl.keyFinancialFigures('parent-statement')} />
              }
            />
          </Show>
          <ViewSwitch
            actionChange={() => setAppendCurrencyURL(!appendCurrencyURL)}
            checkedVar={!appendCurrencyURL}
            textOff={
              <FormattedMessage id={intl.keyFinancialFigures('selected-currency')} />
            }
            textOn={
              <FormattedMessage id={intl.keyFinancialFigures('original-currency')} />
            }
          />
        </Box>

        {/********
         * TABLE *
         *********/}

        <Sidescroll conditionalySidescroll maxWidth={'1240px'}>
          <table
            data-cy="key-financial-figures-table"
            style={{ width: isSmallDisplay ? null : '100%' }}
            className="table table-alternate"
          >
            <thead className={cx(classes.table_head, classes.textRight)}>
              <tr>
                <th></th>
                {periods.length
                  ? periods?.map((period, i) => (
                      <th key={i}>
                        <Typography variant="subtitle2" className={cx(classes.th)}>
                          {`${formatToUniversalDate(period.start)} -
                            ${formatToUniversalDate(period.end)}`}
                        </Typography>
                      </th>
                    ))
                  : null}
              </tr>
              <tr>
                <th></th>
                {periods?.map((val, i) => {
                  const displayedCurrency = !appendCurrencyURL
                    ? currency[i]
                    : userCurrency || '-'
                  return (
                    <th key={i} className={classes.textRight}>
                      <Typography variant="subtitle2">{displayedCurrency}</Typography>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className={cx(classes.table_body)}>
              {keyFinancialFigures?.map((figure, i) => (
                <ConditionallyRender
                  key={`${figure.label}, ${i}`}
                  condition={
                    figure?.type === 'array' &&
                    figure?.availableIn?.includes(localId?.country)
                  }
                  when={
                    <tr>
                      <FormattedMessage id={intl.keyFinancialFigures(figure.label)}>
                        {(text) => (
                          <TableRowTitle variant={'subtitle2'}>{text}</TableRowTitle>
                        )}
                      </FormattedMessage>
                      {figure.data?.map((val, i) => (
                        <TableRowValue key={i}>{val}</TableRowValue>
                      ))}
                    </tr>
                  }
                  otherwise={
                    <ConditionallyRender
                      condition={
                        isShowMore && figure?.availableIn?.includes(localId?.country)
                      }
                      when={
                        <FormattedMessage id={intl.keyFinancialFigures(figure.label)}>
                          {(text) => (
                            <AnimatedTableRow
                              isSpace={figure.isSpace}
                              isDash={figure.isDash}
                              classes={classes}
                              data={figure.data}
                              label={text}
                              delay={Math.floor(Math.random() * 300)}
                            />
                          )}
                        </FormattedMessage>
                      }
                    />
                  }
                />
              ))}
              <tr className="table-nobg">
                <td></td>
                {annualReportLinks?.map((url, i) => (
                  <td key={i}>
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                      <AnnualReport url={url} />
                    </Box>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </Sidescroll>
        {isShowMore
          ? financialStatsToShow?.[0]?.auditor.type_of_assistance != null && (
              <>
                <Typography variant="h4">
                  <FormattedMessage id={intl.annualReportInformation('title')} />
                </Typography>
                <Sidescroll conditionalySidescroll maxWidth={'1241px'}>
                  <AuditorInfo />
                </Sidescroll>
              </>
            )
          : null}
      </Stack>
    </LoadingSkeleton>
  )
}

export default KeyFinancialFigures
