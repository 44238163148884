import * as React from 'react'
import { HistoryHeader } from 'components'
import { Box, Divider } from '@mui/material'
import BuildArea from './widgets/BuildArea'
import { ReactFlowProvider } from 'reactflow'
import { allowedCountries } from 'pages/enterprise-credit-policy/EnterpriseCreditPolicy.model'
import InvalidCountry from './widgets/Error/InvalidCountry'
import { EditPolicySidePanel } from '../SidePanels'
import ActionPanel from './widgets/ActionPanel'
import CreditPolicyBuilderErrorBoundary from './widgets/Error/CreditPolicyBuilderErrorBoundary'
import { useCreditPolicyGet, useUserSelf } from 'services/queries'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { BuildAreRef, PolicyBuilderParams } from './PolicyBuilder.types'
import InvalidParameters from './widgets/Error/InvalidParameters'
import { useMeasure } from 'react-use'
import { useBuilderParams } from './PolicyBuilder.hooks'
import { ButtonPrimary, ButtonTertiary } from 'components-new'
import {
  useHandleDuplicatePolicy,
  useHandleExportPolicy,
  useHandleSave,
  useHandleSaveAndActivate,
  useSavePolicy,
} from './widgets/NavBarActions/NavBarActions.controller'
import NavBarPanels from './widgets/NavBarActions/NavBarPanels'

function PolicyBuilder() {
  const testButtonRef = React.useRef<HTMLButtonElement>(null)
  const saveAsDraftButtonRef = React.useRef<HTMLButtonElement>(null)
  const publishButtonRef = React.useRef<HTMLButtonElement>(null)

  const exportButtonRef = React.useRef<HTMLButtonElement>(null)
  const duplicateButtonRef = React.useRef<HTMLButtonElement>(null)
  const editPolicySettingsButtonRef = React.useRef<HTMLButtonElement>(null)
  const deleteButtonRef = React.useRef<HTMLButtonElement>(null)

  const [dialogState, setDialogState] = React.useState({
    showIncompletePolicyModal: false,
    otherActivePolicyName: '',
    showActiveDialog: false,
    isTestPolicyPanelOpen: false,
    isEditPolicyPanelOpen: false,
    isDeletePolicyPanelOpen: false,
  })

  const [editPolicyPanelIsOpen, setEditPolicyPanelIsOpen] = React.useState(false)
  const params = useBuilderParams() as PolicyBuilderParams | undefined
  const builderRef = React.useRef<BuildAreRef>(null)
  const { data: policyData } = useCreditPolicyGet(params?.policyId ?? 'null')

  const [boxRef, { width, height }] = useMeasure()

  const { data, isLoading } = useUserSelf()
  const country = params?.country?.toUpperCase()
  type HandleSetDialogStateProps = {
    key:
      | 'showIncompletePolicyModal'
      | 'otherActivePolicyName'
      | 'showActiveDialog'
      | 'isTestPolicyPanelOpen'
      | 'isEditPolicyPanelOpen'
      | 'isDeletePolicyPanelOpen'
    value: boolean | string
  }
  const handleSetDialogState = ({ key, value }: HandleSetDialogStateProps) => {
    setDialogState((prevState) => ({ ...prevState, [key]: value }))
  }
  const { handleSave } = useHandleSave()
  const { savePolicy } = useSavePolicy()
  const { handleSaveAndUpdate } = useHandleSaveAndActivate({
    setShowIncompletePolicyModal: (value) =>
      handleSetDialogState({ key: 'showIncompletePolicyModal', value }),
    setOtherActivePolicyName: (value) =>
      handleSetDialogState({ key: 'otherActivePolicyName', value }),
    setShowActiveDialog: (value) =>
      handleSetDialogState({ key: 'showActiveDialog', value }),
    policyId: params?.policyId ?? '0',
  })
  const { handleExportPolicy } = useHandleExportPolicy(policyData)
  const { handleDuplicatePolicy } = useHandleDuplicatePolicy(policyData)

  if (!country || !allowedCountries.includes(country)) {
    return <InvalidCountry />
  }
  if (!params) {
    // TODO: Add better validation for params and improve the message on the page
    return <InvalidParameters />
  }
  if (isLoading && !data) {
    //  TODO: Add loading state
    return (
      <div>
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <>
      <CreditPolicyBuilderErrorBoundary userData={data}>
        <HistoryHeader
          secondaryActions={[
            'duplicate-policy-button',
            'export-policy-button',
            'edit-policy-settings-policy-button',
            'delete-policy-button',
          ]}
          title={params.name}
          titleExtraProps={{
            divider: <Divider orientation="vertical" flexItem light />,
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            spacing: 3,
            width: 1,
          }}
          location="credit-policy-builder"
          historyKey="Credit Policy Builder"
          country=""
          subheader=""
        >
          <ButtonTertiary
            key="test-policy"
            ref={testButtonRef}
            onClick={() =>
              handleSetDialogState({ key: 'isTestPolicyPanelOpen', value: true })
            }
          >
            {<FormattedMessage id={intl.creditPolicyNew('test')} />}
          </ButtonTertiary>
          <ButtonTertiary
            key="save-as-draft"
            ref={saveAsDraftButtonRef}
            onClick={handleSave}
          >
            {<FormattedMessage id={intl.creditPolicyNew('save-as-draft')} />}
          </ButtonTertiary>
          <ButtonPrimary
            key="save-and-publish"
            ref={publishButtonRef}
            data-cy="save-and-publish"
            onClick={handleSaveAndUpdate}
          >
            {<FormattedMessage id={intl.creditPolicyNew('publish')} />}
          </ButtonPrimary>

          {/* Secondary buttons */}
          <ButtonPrimary
            key="export-policy-button"
            ref={exportButtonRef}
            data-cy="policy-card-action-export"
            onClick={handleExportPolicy}
          >
            <FormattedMessage id={intl.creditPolicyNew('export')} />
          </ButtonPrimary>
          <ButtonPrimary
            key="duplicate-policy-button"
            ref={duplicateButtonRef}
            data-cy="policy-card-action-duplicate"
            onClick={handleDuplicatePolicy}
          >
            {<FormattedMessage id={intl.creditPolicyNew('duplicate')} />}
          </ButtonPrimary>
          <ButtonPrimary
            key="edit-policy-settings-policy-button"
            ref={editPolicySettingsButtonRef}
            data-cy="policy-card-action-edit-policy-settings"
            onClick={() =>
              handleSetDialogState({
                key: 'isEditPolicyPanelOpen',
                value: !dialogState.isEditPolicyPanelOpen,
              })
            }
          >
            {<FormattedMessage id={intl.creditPolicyNew('edit-policy-settings')} />}
          </ButtonPrimary>
          <ButtonPrimary
            key="delete-policy-button"
            ref={deleteButtonRef}
            data-cy="policy-card-action-delete"
            onClick={() =>
              handleSetDialogState({
                key: 'isDeletePolicyPanelOpen',
                value: !dialogState.isDeletePolicyPanelOpen,
              })
            }
          >
            {<FormattedMessage id={intl.creditPolicyNew('delete')} />}
          </ButtonPrimary>
        </HistoryHeader>
        <NavBarPanels
          policyName={params.name}
          isTestPolicyPanelOpen={dialogState.isTestPolicyPanelOpen}
          isDeletePolicyPanelOpen={dialogState.isDeletePolicyPanelOpen}
          isEditPolicyPanelOpen={dialogState.isEditPolicyPanelOpen}
          showActiveDialog={dialogState.showActiveDialog}
          showIncompletePolicyModal={dialogState.showIncompletePolicyModal}
          otherActivePolicyName={dialogState.otherActivePolicyName}
          setShowActiveDialog={(value) =>
            handleSetDialogState({ key: 'showActiveDialog', value })
          }
          setIsTestPolicyPanelOpen={(value) =>
            handleSetDialogState({ key: 'isTestPolicyPanelOpen', value })
          }
          setShowIncompletePolicyModal={(value) =>
            handleSetDialogState({ key: 'showIncompletePolicyModal', value })
          }
          setIsEditPolicyPanelOpen={(value) =>
            handleSetDialogState({ key: 'isEditPolicyPanelOpen', value })
          }
          setIsDeletePolicyPanelOpen={(value) =>
            handleSetDialogState({ key: 'isDeletePolicyPanelOpen', value })
          }
          policyId={params?.policyId}
          params={params}
          savePolicy={savePolicy}
          handleSave={handleSave}
        />
        <Box ref={boxRef} margin={'20px'} flex={1} height="100%">
          <ReactFlowProvider>
            <BuildArea
              parentDimensions={{ width, height }}
              ref={builderRef}
              policyId={params.policyId ?? 'null'}
            />
            <ActionPanel />
          </ReactFlowProvider>
          {editPolicyPanelIsOpen && (
            <EditPolicySidePanel
              isOpen={editPolicyPanelIsOpen}
              setIsOpen={setEditPolicyPanelIsOpen}
              policyId={params.policyId}
            />
          )}
        </Box>
      </CreditPolicyBuilderErrorBoundary>
    </>
  )
}

export default PolicyBuilder
