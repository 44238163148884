// @flow

/* eslint-disable no-case-declarations */
import { DOMAIN as _ } from 'configs/constants/system'
import { loadFilters } from 'services/api'

/****************
 * Action Types *
 ****************/

export const ADD_FILTER = `${_} ADD_FILTER`
export const SET_FILTERS = `${_} SET_FILTERS`
export const REMOVE_FILTER = `${_} REMOVE_FILTER`
export const UPDATE_FILTER = `${_} UPDATE_FILTER`
export const UPDATE_FILTER_OPTION = `${_} UPDATE_FILTER_OPTION`
export const SET_FILTER_COUNTRY = `${_} SET_FILTER_COUNTRY`

export const RESET_FILTERS = `${_} RESET_FILTERS`
export const CLEAN_FILTER_RESULTS = `${_} CLEAN_SEARCH_RESULTS`
export const SET_FILTER_RESULTS = `${_} SET_FILTER_RESULTS`

export const SET_SAVED_FILTERS = `${_} SET_SAVED_FILTERS`

/*****************
 * Initial State *
 *****************/

const initialState = {
  queries: [],
  filterCountry: 'dk',
  filters: [],
  savedFilters: [],
  filterOptions: [
    { filter: 'hide_advertisement_protected', value: false },
    { filter: 'has_email', value: false },
    { filter: 'has_webpage', value: false },
    { filter: 'has_phone_number', value: false },
    { filter: 'show_inactive', value: false },
    { filter: 'has_auditor', value: false },
    // { filter: 'hide_holding_company', value: false },
  ],
  companyCount: 0,
  timeElapsed: null,
  companyResults: [],
}

/*************************
 * Async Action Creators *
 *************************/

export const fetchSavedFilters = () => {
  return (dispatch) => {
    loadFilters()
      .then(({ data }) => {
        dispatch(setSavedFilters(data.filters))
      })
      .catch((error) => console.log('ERROR', error))
  }
}

/*******************
 * Action Creators *
 *******************/

export const setFilterCountry = (country) => ({
  type: SET_FILTER_COUNTRY,
  country,
})

export const addFilter = (filter) => ({
  type: ADD_FILTER,
  filter,
})

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  filters,
})

export const updateFilterOption = (filterOption) => ({
  type: UPDATE_FILTER_OPTION,
  filterOption,
})

export const removeFilter = (filter) => ({
  type: REMOVE_FILTER,
  filter,
})

export const updateFilter = (filter) => ({
  type: UPDATE_FILTER,
  filter,
})

export const resetFilters = () => ({
  type: RESET_FILTERS,
})

export const cleanFilterResults = () => ({
  type: CLEAN_FILTER_RESULTS,
})

export const setFilterResults = (payload) => ({
  type: SET_FILTER_RESULTS,
  payload,
})

export const setSavedFilters = (savedFilters) => ({
  savedFilters,
  type: SET_SAVED_FILTERS,
})

/***********
 * Reducer *
 ***********/

export const advancedFilters = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_FILTER_COUNTRY:
      return {
        ...initialState,
        savedFilters: state.savedFilters,
        filterCountry: action.country.toLowerCase(),
      }
    case ADD_FILTER:
      const addFilter = state.filters.filter((x) => x.filter === action.filter.filter)
        .length
        ? state.filters
        : [...state.filters, action.filter]
      return {
        ...state,
        filters: addFilter,
      }
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      }
    case UPDATE_FILTER:
      return {
        ...state,
        filters: state.filters.map((x) =>
          x.filter === action.filter.filter ? action.filter : x
        ),
      }
    case REMOVE_FILTER:
      return {
        ...state,
        filters: state.filters.filter((x) => x.filter !== action.filter),
      }
    case RESET_FILTERS:
      return {
        ...initialState,
        savedFilters: state.savedFilters,
        filterCountry: state.filterCountry,
      }
    case UPDATE_FILTER_OPTION:
      return {
        ...state,
        filterOptions: state.filterOptions.map((x) =>
          x.filter === action.filterOption ? { ...x, value: !x.value } : x
        ),
      }
    case SET_FILTER_RESULTS:
      return {
        ...state,
        companyCount: action.payload.count,
        timeElapsed: action.payload.time_elapsed,
        companyResults: action.payload.results,
      }
    case SET_SAVED_FILTERS:
      return {
        ...state,
        savedFilters: action.savedFilters,
      }
    default:
      return { ...state }
  }
}

export default advancedFilters
