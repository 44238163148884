import { useCallback, useImperativeHandle, forwardRef, useState } from 'react'
import ReactFlow, {
  Controls,
  applyNodeChanges,
  NodeChange,
  EdgeChange,
  applyEdgeChanges,
} from 'reactflow'
import { useBuilder } from '../../BuilderContext'
import { edgeTypes, nodeTypes } from '../../PolicyBuilder.model'
import {
  useCreditPolicyGet,
  useCreditPolicyUpdate,
  useECPDataStructure,
} from 'services/queries'
import { BuildAreRef, PolicyBuilderParams } from '../../PolicyBuilder.types'
import './BuildArea.css'
import 'reactflow/dist/style.css'
import InvalidParameters from '../Error/InvalidParameters'
import StartHerePaper from '../StartHerePaper'
import {
  useBuilderParams,
  useLoadPolicyIntoBuilder,
  useLoadPredefinedPolicyStructure,
} from '../../PolicyBuilder.hooks'
import { handleUpdatePolicy } from '../../PolicyBuilder.controller'
import { useQueryClient } from 'react-query'
import { UseUserSelfResponse } from 'types/queries'
import NoAccessToFeature from '../Error/NoAccessToFeature'
import { ErrorPageSimple } from 'components-new'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import EditActivePolicyDialog from '../../../FloatingActionMenu/widgets/EditActivePolicyDialog'

function BuildArea(
  {
    policyId,
    parentDimensions,
  }: { policyId: string | null; parentDimensions: { width: number; height: number } },
  ref: React.ForwardedRef<BuildAreRef>
) {
  const [isFirstClick, setIsFirstClick] = useState(true)
  const [editActivePolicyDialogIsOpen, setEditActivePolicyDialogIsOpen] = useState(false)
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData('userSelf') as UseUserSelfResponse
  const role = userData?.role
  const { state, actions } = useBuilder()
  const { handleEdgesChange, handleNodesChange } = actions
  const params = useBuilderParams() as PolicyBuilderParams
  const { mutate: updatePolicy } = useCreditPolicyUpdate()
  const { isLoading: isECPDataStructureLoading } = useECPDataStructure(params.country) // Prefetching stuff
  const {
    data: policyData,
    isLoading: isPolicyDataLoading,
    isError,
  } = useCreditPolicyGet(policyId)
  useImperativeHandle(
    ref,
    () => ({
      handleSave: () =>
        handleUpdatePolicy({
          params,
          enabled: false,
          state,
          updateFunction: updatePolicy,
        }),
    }),
    [params, state, updatePolicy]
  )

  // This is an effect that will load the policy when data is provided
  useLoadPolicyIntoBuilder({
    policyId,
    policyData,
    state,
    actions,
    params,
  })

  useLoadPredefinedPolicyStructure({
    params,
    policyId,
    structureType: params.createActiveRule === 'true' ? 'create-active-rule' : null,
  })

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      return handleNodesChange(applyNodeChanges(changes, state.nodes))
      // Here is when the changes are applied to the nodes. Here we calculate the horizontal and vertical positions
    },
    [state, handleNodesChange]
  )
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => handleEdgesChange(applyEdgeChanges(changes, state.edges)),
    [handleEdgesChange, state.edges]
  )

  if (!params) {
    // TODO: Add better validation for params and improve the message on the page
    return <InvalidParameters />
  }
  if (role !== 'admin') {
    return <NoAccessToFeature />
  }

  if (isPolicyDataLoading || parentDimensions.height === 0 || isECPDataStructureLoading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    )
  }
  if (isError && params.policyId !== 'null') {
    return (
      <ErrorPageSimple
        content={<FormattedMessage id={intl.creditPolicyNew('error-loading-policy')} />}
      />
    )
  }

  const handleNodeClick = () => {
    if (isFirstClick && policyData?.enabled) {
      setEditActivePolicyDialogIsOpen(true)
    }
  }

  return policyId === 'null' ? (
    <StartHerePaper />
  ) : (
    <ReactFlow
      deleteKeyCode={null}
      defaultViewport={{ x: 60, y: parentDimensions.height / 2 - 100 / 2, zoom: 1 }}
      nodes={state.nodes}
      onNodesChange={onNodesChange}
      edges={state.edges}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodeClick={handleNodeClick}
      proOptions={{ hideAttribution: true }}
    >
      <Controls position="top-left" showFitView={false} showInteractive={false} />
      <EditActivePolicyDialog
        open={editActivePolicyDialogIsOpen}
        setOpen={setEditActivePolicyDialogIsOpen}
        policyData={policyData!}
        confirmSelection={() => setIsFirstClick(false)}
        onClose={() => console.log('on close')}
      />
    </ReactFlow>
  )
}
export default forwardRef(BuildArea)
