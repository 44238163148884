import { withRouter } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import { default as cx } from 'classnames'
import moment from 'moment'
import { navigation } from 'services/navigation'

import { Company, RisikaScore, TableCell, IntlReactTable } from 'components'
import { minRows } from 'configs/constants/system'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { Typography } from '@mui/material'
import { formatToUniversalDate } from 'utils/formatDate'

const styles = () => ({
  root: {},
})

const blacklistedCompanyType = (companyType) =>
  ['enk'].includes(companyType?.toLowerCase())

const Functions = ({ functions, isActive }) => (
  <TableCell align="start" isActive={isActive}>
    {functions.map((fn, i) => {
      const separator = i < functions.length - 1 ? ', ' : null
      return (
        <span key={i}>
          {fn.title}
          {separator} &nbsp;
        </span>
      )
    })}
  </TableCell>
)

export const Table = ({ classes, data, history }) => {
  // TODO: Investigate bug in data not being an array
  if (!data || !data.map) data = []
  const map = (data) =>
    data
      .map((row) => {
        const isActive = row.company_status_code === 'ACTIVE'
        return {
          ...row,
          display_company: (
            <Company
              isActive={isActive}
              primary={row.company_name}
              secondary={row.company_status}
            />
          ),
          sort_company: `${row.company_name}`,
          sort_risika_score: Number(row.score) || 0,
          display_risika_score: (
            <RisikaScore
              value={row.score || '-'}
              displayNumericScore={!blacklistedCompanyType(row.company_type.short)}
            />
          ),
          display_local_id: (
            <TableCell isActive={isActive}>{row.local_organization_id.id}</TableCell>
          ),
          sort_local_id: Number(row.local_organization_id.id) || 0,
          sort_function: row.functions,
          display_function: <Functions functions={row.functions} isActive={isActive} />,
          sort_function_status: !row.valid_to,
          sort_start_date: moment(row.valid_from).valueOf(),
          display_start_date: (
            <TableCell isActive={isActive}>
              {formatToUniversalDate(row.valid_from)}
            </TableCell>
          ),
          sort_end_date: row.valid_to ? moment(row.valid_to).valueOf() : 0,
          display_end_date: (
            <TableCell isActive={isActive}>
              {formatToUniversalDate(row.valid_to)}
            </TableCell>
          ),
          sort_active_relation: row.valid_to
            ? moment(row.valid_to).valueOf()
            : moment().valueOf(),
          display_active_relation: (
            <TableCell isActive={isActive}>
              {row.valid_to ? (
                <FormattedMessage id={intl.generic('inactive')} />
              ) : (
                <FormattedMessage id={intl.generic('active')} />
              )}
            </TableCell>
          ),
        }
      })
      .sort((a, b) => moment(a.valid_to).valueOf() - moment(b.valid_to).valueOf()) // sort by valid_to, active relations first

  const handleClickOnRow = (state, rowInfo) => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (!rowInfo) return
        const {
          local_organization_id: { id, country },
        } = rowInfo.original
        history.push(navigation.creditCheck({ country, id }))
      },
    }
  }

  return (
    <IntlReactTable
      className={cx(classes.root, '-striped -highlight border-0')}
      data={map(data)}
      minRows={minRows}
      noDataText={<FormattedMessage id={intl.generic('no-data')} />}
      getTdProps={handleClickOnRow}
      columns={[
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.generic('company')} />
            </Typography>
          ),
          accessor: 'sort_company',
          filterable: false,
          Cell: (row) => row.original.display_company,
          width: 360,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.generic('risika-score')} />
            </Typography>
          ),
          accessor: 'sort_risika_score',
          filterable: false,
          className: 'font-bold justify-center',
          Cell: (row) => row.original.display_risika_score,
          width: 140,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.generic('local-id')} />
            </Typography>
          ),
          accessor: 'sort_local_id',
          filterable: false,
          className: 'font-bold justify-center',
          Cell: (row) => row.original.display_local_id,
          width: 140,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.relationOverview('function')} />
            </Typography>
          ),
          accessor: 'sort_function',
          filterable: false,
          className: 'font-bold justify-center',
          Cell: (row) => row.original.display_function,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.relationOverview('function-status')} />
            </Typography>
          ),
          accessor: 'sort_active_relation',
          filterable: false,
          className: 'font-bold justify-center',
          Cell: (row) => row.original.display_active_relation,
          width: 200,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.relationOverview('start-date')} />
            </Typography>
          ),
          accessor: 'sort_start_date',
          filterable: false,
          className: 'font-bold justify-center',
          Cell: (row) => row.original.display_start_date,
          width: 200,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.relationOverview('end-date')} />
            </Typography>
          ),
          accessor: 'sort_end_date',
          filterable: false,
          className: 'font-bold justify-center',
          Cell: (row) => row.original.display_end_date,
          width: 200,
        },
      ]}
    />
  )
}

export default withStyles(styles, { withTheme: true })(withRouter(Table))
