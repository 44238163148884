import { FormattedMessage } from 'react-intl'
import PaperContainer from '../PaperContainer'
import intl from 'localization/components'
import { useCompanyVisitsSummary } from 'services/queries/useCompanyVisitsSummary'
import SearchesOverview from './SearchesOverview'
import SearchesOverviewLoading from './VisitsSummary.loading'
import { ComponentStateHandler, ErrorPageSimple } from 'components-new'

function VisitsSummary() {
  const { data: companyVisitsSummary, isLoading, isError } = useCompanyVisitsSummary()

  return (
    <PaperContainer
      height={'212px'}
      title={<FormattedMessage id={intl.home('searches-this-month')} />}
    >
      <ComponentStateHandler
        isLoading={isLoading}
        loading={<SearchesOverviewLoading />}
        isError={isError}
        error={<ErrorPageSimple content={''} />}
      >
        {!!companyVisitsSummary && <SearchesOverview searches={companyVisitsSummary} />}
      </ComponentStateHandler>
    </PaperContainer>
  )
}
export default VisitsSummary
