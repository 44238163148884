import React from 'react'
import moment from 'moment'

import * as config from './config'

const Wootric = ({ user }) => {
  const getWootricData = (user) => {
    let email
    let createdAt

    try {
      email = user.data.email
      createdAt = moment(user.data.createdAt, 'YYYY-MM-DD H:mm').unix()
    } catch (error) {
      //
    }

    return { email, createdAt }
  }

  React.useEffect(() => {
    try {
      const { email, createdAt } = getWootricData(user)

      if (!email || !createdAt) return

      const setupScript = document.createElement('script')
      setupScript.type = 'text/javascript'
      setupScript.async = true

      setupScript.innerHTML = `
        window.wootricSettings = {
          email: "${email}",
          created_at: ${createdAt},
          account_token: "${config.WOOTRIC_ACCOUNT_TOKEN}"
        }
      `

      document.body.appendChild(setupScript)

      // Beacon
      const beacon = document.createElement('script')
      beacon.type = 'text/javascript'
      beacon.async = true
      beacon.src = 'https://disutgh7q0ncc.cloudfront.net/beacon.js'
      beacon.onload = function () {
        window.wootric('run')
      }

      document.body.appendChild(beacon)
    } catch (error) {
      console.error('Issues Wootric', error)
    }
  }, [user])

  return <div />
}

export default Wootric
