type ComponentStateHandlerProps = {
  isLoading?: boolean
  isError?: boolean
  loading?: React.ReactNode
  error?: React.ReactNode
  isEmptyState?: boolean
  emptyState?: React.ReactNode
  children: React.ReactNode
}

function ComponentStateHandler({
  isLoading,
  isError,
  loading,
  error,
  isEmptyState,
  emptyState,
  children,
}: ComponentStateHandlerProps) {
  if (isLoading) {
    return loading ?? 'Loading...'
  }
  if (isError) {
    return error ?? 'Error...'
  }
  if (isEmptyState) {
    return emptyState ?? null
  }
  return children
}

export default ComponentStateHandler
