/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDebounce } from 'react-use'
import FlagSelect from 'components-new/elements/FlagSelect'
import Selector from './Selector'
import { setSearchResults, setSearchPerson } from 'store_deprecated'
import { navigation } from 'services/navigation'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { useCompanySearch } from 'services/queries/useCompanySearch'
import { usePersonSearch } from 'services/queries/usePersonSearch'
import { useAppShell } from './context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useSnackbar } from 'notistack'
import { useUserSettingsField } from 'services/queries'
import { AvailableCountries } from 'globalTypes'
import { Icon } from '@fortawesome/fontawesome-svg-core'
import {
  CloseSearch,
  CloseSearchIcon,
  Root,
  SearchField,
} from 'styles/components/searchbar'

const SearchBar = () => {
  const reactIntl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const appShell = useAppShell()
  const { data: searchCountry } = useUserSettingsField('selected_country')
  const searchSelected = appShell.searchActive
  const tableContent = appShell.resultType

  const [searchText, setSearchText] = useState('')
  const [debouncedSearchText, setDebouncedSearchText] = useState('')
  const companySearchQuery = useCompanySearch(
    searchText,
    searchCountry as AvailableCountries
  )

  const personSearchQuery = usePersonSearch(
    searchText,
    searchCountry as AvailableCountries
  )
  const { enqueueSnackbar } = useSnackbar()
  const debounceTime = 300
  useDebounce(
    () => {
      setSearchText(debouncedSearchText)
    },
    debounceTime,
    [debouncedSearchText]
  )

  useEffect(() => {
    if (!searchText) return

    if (companySearchQuery?.error) {
      enqueueSnackbar(`${companySearchQuery?.error?.response?.data?.error}`, {
        variant: 'error',
      })
    }

    if (personSearchQuery?.error) {
      enqueueSnackbar(`${personSearchQuery?.error?.response?.data?.error}`, {
        variant: 'error',
      })
    }
  }, [companySearchQuery?.error, enqueueSnackbar, personSearchQuery?.error, searchText])

  const cleanupSearch = useCallback(
    (state: 'companies' | 'person') => {
      const initialState = {
        search_result: [],
        count: 0,
        rows: {},
      }
      if (state === 'companies') {
        return dispatch(setSearchResults(initialState))
      }
      if (state === 'person') {
        return dispatch(setSearchPerson(initialState))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (companySearchQuery.data) {
      if (
        !companySearchQuery.data.count &&
        personSearchQuery.data &&
        personSearchQuery.data.count
      ) {
        cleanupSearch('companies')
        appShell.setResultType('person')
      }
      dispatch(setSearchResults(companySearchQuery.data))
    }
  }, [companySearchQuery.data, personSearchQuery.data, dispatch])

  useEffect(() => {
    if (personSearchQuery.data) {
      if (
        !personSearchQuery.data.count &&
        companySearchQuery.data &&
        companySearchQuery.data.count
      ) {
        cleanupSearch('person')
        appShell.setResultType('company')
      }
      dispatch(setSearchPerson(personSearchQuery.data))
    }
  }, [personSearchQuery.data, companySearchQuery.data, dispatch])

  const localId = companySearchQuery.data?.search_result?.[0]?.local_organization_id as
    | string
    | undefined

  const personId = {
    id: personSearchQuery.data?.search_result?.[0]?.personal_id,
    country: personSearchQuery.data?.search_result?.[0]?.country,
  }

  const EntitySelector = useCallback(() => <Selector />, [])

  const goToFirstCompany = (localId: string) => {
    history.push(navigation.creditCheck(localId))
  }

  const goToFirstPerson = (personId: {
    id: string | number | undefined
    country: string | undefined
  }) => {
    history.push(navigation.relationOverview(personId))
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      event.currentTarget.blur()
    }

    if (event.key === 'Enter' || event.code === 'Enter') {
      if (tableContent === 'company' && companySearchQuery.data?.search_result.length) {
        goToFirstCompany(localId || '')
        handleCloseSearch()
        event.currentTarget.blur()
      } else if (
        tableContent === 'person' &&
        companySearchQuery.data?.search_result.length
      ) {
        goToFirstPerson(personId)
        handleCloseSearch()
        event.currentTarget.blur()
      }
      event.preventDefault()
    }
  }

  const handleOnFocus = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.currentTarget.select()
    appShell.setSearchActive(true)
  }

  const handleCloseSearch = () => {
    appShell.setSearchActive(false)
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDebouncedSearchText(event.target.value)
  }

  return (
    <Root data-cy="searchBar">
      <SearchField
        placeholder={reactIntl.formatMessage({
          id: intl.searchBusiness('search-placeholder'),
        })}
        onKeyDown={handleKeyDown}
        onFocus={handleOnFocus}
        leftAdornment={FlagSelect}
        rightAdornment={EntitySelector}
        onChange={handleOnChange}
        data-cy="search"
        value={debouncedSearchText}
      />
      {searchSelected ? (
        <CloseSearch elevation={0} data-cy="close-search-icon">
          <CloseSearchIcon onClick={handleCloseSearch}>
            <FontAwesomeIcon icon={faTimes as Icon} />
          </CloseSearchIcon>
        </CloseSearch>
      ) : null}
    </Root>
  )
}

export default SearchBar
