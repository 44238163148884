import { AxiosPromise } from 'axios'
import { useQuery } from 'react-query'
import { accountStatus } from 'services/api'

export const ACCOUNT_STATUS = 'accountStatus'

export function useAccountStatus() {
  return useQuery(ACCOUNT_STATUS, () => accountStatus(), {
    staleTime: 100000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}

export async function getAxiosData<T>(promise: AxiosPromise<T>) {
  return promise.then((res) => res.data)
}
