import * as d3 from 'd3'
import { chartColors } from 'configs/constants/theme'

const width = 290
const height = 290
const radius = width / 2

export default class D3PieChart {
  constructor(element, data) {
    const vis = this
    const getAverageShare = (share) => {
      if (share.includes('-')) {
        const first = share.substring(0, share.lastIndexOf('-'))
        const second = share.substring(share.lastIndexOf('-') + 1, share.lastIndexOf('%'))
        return ((parseFloat(first) + parseFloat(second)) / 2).toFixed(2)
      } else {
        return share.substring(0, share.lastIndexOf('%'))
      }
    }

    vis.pieData = data
      .filter((x) => x.shares?.length)
      .map((y) => ({
        name: y.name,
        tableShares: getAverageShare(y.shares),
        textShares: `${y.shares.substring(0, 4)}%`,
      }))
    let colorArray = Object.keys(chartColors).map((x) => chartColors[x])
    colorArray = [...colorArray, ...colorArray, ...colorArray]

    // const colors = ['red', 'blue']
    vis.color = d3.scaleOrdinal().range(colorArray)

    vis.arc = d3
      .arc()
      .outerRadius(radius - 100)
      .innerRadius(radius - 70)

    vis.labelArc = d3
      .arc()
      .outerRadius(radius - 100)
      .innerRadius(radius - 70)

    // pie generator
    vis.pie = d3
      .pie()
      .sort(null)
      .value((d) => d.tableShares)

    vis.svg = d3
      .select(element)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2.5})`)

    // append the arcs
    vis.g = vis.svg
      .selectAll('.arc')
      .data(vis.pie(vis.pieData))
      .enter()
      .append('g')
      .attr('class', 'arc')

    // const pieTween = (b) => {
    //   b.innerRadius = 0
    //   const i = d3.interpolate({ startAngle: 0, endAngle: 0 }, b)
    //   return (t) => vis.arc(i(t))
    // }
    vis.divNode = d3.select(element).node()
    vis.g
      .append('line')
      .attr('x1', 0)
      .style('display', (d) => (d.endAngle - d.startAngle < 0.1 ? 'none' : ''))
      .attr('y1', 0)
      .attr('x2', (d) => {
        const c = vis.arc.centroid(d)
        return -c[0] / 2.6
      })
      .attr('y2', (d) => {
        const c = vis.arc.centroid(d)
        return -c[1] / 2.6
      })
      .attr('stroke-width', 1)
      .attr('stroke', 'darkgrey')
      .attr('opacity', 1)
      .attr('transform', (d) => {
        const c = vis.arc.centroid(d)
        return `translate(${c[0] * 1.631}, ${c[1] * 1.631})`
      })
    // append the path of the arc
    const arcPath = vis.g
      .append('path')
      .attr('d', vis.arc)
      .style('fill', (d) => vis.color(d.data.name))
      .on('mousemove', function (event, d) {
        const e = arcPath.nodes()
        const i = e.indexOf(this)
        const item = d3.select(e[i])
        item
          .attr('stroke', 'lightgray')
          .attr('d', vis.arc.innerRadius(radius - 67).outerRadius(radius - 103))

        // Show tooltip and add text
        vis.mousePos = d3.pointer(event, vis.divNode)

        d3.select('#mainTooltip')
          .style('left', vis.mousePos[0] - 118.5 + 'px')
          .style('top', vis.mousePos[1] - 53 + 'px')
          .select('#value')
          .attr('text-anchor', 'middle')
          // .html(d.data.str_lab + "<br />" + d.data.num);
          .html(
            `${
              d.data.name.length > 39
                ? `${d.data.name.substring(0, 39)}...`
                : `${d.data.name}`
            }`
          )

        d3.select('#mainTooltip').classed('hidden', false)
      })
      .on('mouseout', function (event, d) {
        const e = arcPath.nodes()
        const i = e.indexOf(this)
        const item = d3.select(e[i])
        item
          .attr('stroke', 'white')
          .attr('d', vis.arc.innerRadius(radius - 70).outerRadius(radius - 100))

        d3.select('#mainTooltip').classed('hidden', true)
      })

    vis.svg.append('g').classed('labels', true)
    vis.svg.append('g').classed('lines', true)

    vis.outerArc = d3
      .arc()
      .outerRadius(radius - 165)
      .innerRadius(radius)

    vis.g
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('opacity', 1)
      .attr('transform', (d) => {
        const c = vis.labelArc.centroid(d)
        return `translate(${c[0] * 1.8}, ${c[1] * 1.8})`
      })
      .attr('dy', '.45rem')
      .style('display', (d) => (d.endAngle - d.startAngle < 0.1 ? 'none' : ''))
      .style('font-family', 'Lato')
      .style('font-size', 14)
      .text(
        (d) =>
          `${d.data.textShares.substring(
            0,
            d.data.textShares.indexOf('-') !== -1
              ? d.data.textShares.indexOf('-') + 1
              : d.data.textShares.length
          )}`
      )
    vis.g
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('opacity', 1)
      .attr('transform', (d) => {
        const c = vis.labelArc.centroid(d)
        return `translate(${c[0] * 1.8}, ${c[1] * 1.8})`
      })
      .attr('dy', '18')
      .style('font-family', 'Lato')
      .style('font-size', 14)
      .text(
        (d) =>
          `${
            d.data.textShares.indexOf('-') !== -1
              ? d.data.textShares.substring(d.data.textShares.indexOf('-') + 1)
              : ''
          }`
      )
  }
}
