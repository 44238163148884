import { useState } from 'react'
import { env } from 'services/env'
import { useSnackbar } from 'notistack'
import {
  MenuItem,
  Typography,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { DropdownMenu } from '..'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { fetchRisikaAPIAccessToken } from 'services/api/helpers/authService'
import ShareDialog from '../../pages/credit-check/scenes/ShareReport'
import { trackActivity } from 'services/api'
import withRestrictions from './withRestrictions'
import { RISIKA_PRICING_PAGE } from 'configs/constants/system'
import { useUserSettingsField } from '../../services/queries'
import ArticleIcon from '@mui/icons-material/Article'
import { Mixpanel } from 'services/helpers/mixpanel'
/**
 * Please do not refactor, just re write the whole component instead!
 */

// This one converts 'nb_NO' to 'no-NO', because the API is inconsistent.
const getLanguage = (language) => {
  if (!language) return 'da-DK'
  return language === 'nb_NO' ? 'no-NO' : language
}

const DownloadReportButton = ({
  companyCVR,
  companyName,
  disabled,
  localId,
  listView,
}) => {
  const [loading, setLoading] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const { data: language, isLoading, isError } = useUserSettingsField('language')
  const { data: currency } = useUserSettingsField('selected_currency')

  const downloadReportPDF = async (cvr, companyName) => {
    setLoading(true)

    const accessToken = await fetchRisikaAPIAccessToken()
    axios
      .get(`${env.PROFF_REPORT_API_BASE_URL}/api/download`, {
        // .get(`http://localhost:8007/api/download`, { // Please leave this here for local testing
        timeout: 30000,
        responseType: 'blob',
        headers: {
          Authorization: accessToken,
        },
        params: {
          cvr: cvr,
          country: localId?.country,
          language: getLanguage(language),
          currency: currency.currency,
        },
      })
      .then((response) => {
        trackActivity({
          action: 'report_download',
          data: {
            local_organization_id: {
              id: cvr.toString(),
              country: 'dk',
            },
            success: true,
          },
        })
        Mixpanel.track('User downloaded report', {
          local_organization_id: {
            id: cvr.toString(),
            country: 'dk',
          },
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${companyName} (${cvr}).pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        trackActivity({
          action: 'report_download',
          data: {
            local_organization_id: {
              id: cvr.toString(),
              country: 'dk',
            },
            success: false,
          },
        })

        if (error.code === 'ECONNABORTED') {
          enqueueSnackbar(
            <>
              <FormattedMessage id={intl.snackbar('report-error-timeout')} />
              <Typography variant="p">&nbsp;{companyName}</Typography>
            </>,
            { variant: 'error' }
          )
        } else {
          enqueueSnackbar(
            <FormattedMessage id={intl.snackbar('report-error-something-wrong')} />,
            { variant: 'error' }
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const closeShareDialog = () => {
    setIsDialogOpen(false)
  }

  const RestrictedDropdownMenu = withRestrictions('allow_pdf_reports', {
    title: <FormattedMessage id={intl.restriction('credit-reports-title')} />,
    content: <FormattedMessage id={intl.restriction('credit-reports-description')} />,
    button: {
      text: <FormattedMessage id={intl.restriction('credit-reports-button')} />,
      link: { RISIKA_PRICING_PAGE },
    },
  })(DropdownMenu)

  // Please handle this one better. I have a lot of work to do in the PC006.
  if (isLoading && !language) return <div>Loading...</div>
  if (isError) return <div>Error</div>

  return (
    <Box data-cy="shareReportDropdownMenu">
      {listView ? (
        <>
          <ListItem sx={{ paddingX: 0 }} key="share-report">
            <ListItemButton onClick={() => setIsDialogOpen(true)}>
              <ListItemText
                primary={<FormattedMessage id={intl.creditCheck('share-report')} />}
              />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ paddingX: 0 }} key="generate-report">
            <ListItemButton onClick={() => downloadReportPDF(companyCVR, companyName)}>
              <ListItemText
                primary={<FormattedMessage id={intl.creditCheck('generate-report')} />}
              />
            </ListItemButton>
          </ListItem>
        </>
      ) : (
        <RestrictedDropdownMenu
          icon={<ArticleIcon sx={{ color: 'inherit', fontSize: '16px !important' }} />}
          text={<FormattedMessage id={intl.creditCheck('report-button')} />}
          loading={loading}
          disabled={disabled}
        >
          <MenuItem onClick={() => setIsDialogOpen(true)}>
            <FormattedMessage id={intl.creditCheck('share-report')} />
          </MenuItem>
          <MenuItem onClick={() => downloadReportPDF(companyCVR, companyName)}>
            <FormattedMessage id={intl.creditCheck('generate-report')} />
          </MenuItem>
        </RestrictedDropdownMenu>
      )}
      <ShareDialog
        localId={localId}
        open={isDialogOpen}
        onClose={closeShareDialog}
        loading={(isLoading) => setLoading(isLoading)}
      />
    </Box>
  )
}

export default DownloadReportButton
