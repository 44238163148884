import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography, Box, useMediaQuery } from '@mui/material'
import { default as cx } from 'classnames'
import { ConditionallyRender, ShowHideAllWrapper, CopyItem } from 'components'

import moment from 'moment'
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  sub_title: {
    fontWeight: 700,
    flex: 1,
    whiteSpace: 'nowrap',
    alignSelf: 'baseline',
    [theme.breakpoints.down('md')]: {
      flex: '',
    },
    '&:first-child': {
      marginTop: 0,
    },
  },
  border: {
    borderBottom: '1px solid gray',
  },
  displayNull: {
    display: 'none',
  },
}))

// type Props = {
//   title: Object,
//   content: Array<any>,
//   classNameTitle?: any,
//   classNameContent?: any,
//   elementType?: string,
//   isHistory?: boolean,
//   historyData?: Array<any>,
//   setIsShowAllHistory?: Function,
//   isShowAllHistory?: boolean,
//   isCopyable?: string,
//   dataCy?: string,
// }

const SimpleEntry = ({
  title,
  content,
  classNameTitle,
  isHistory = false,
  historyData = [],
  setIsShowAllHistory,
  isShowAllHistory = false,
  isCopyable = '',
  dataCy,
}) => {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))
  return (
    <div data-cy={dataCy} className={classes.container}>
      <Typography className={cx(classes.sub_title, classNameTitle)} variant="subtitle2">
        {title}
      </Typography>
      {content?.map((x, i) => (
        <React.Fragment key={(x, i)}>
          <Typography
            component={'div'}
            key={i}
            style={{
              flex: isSmallScreen ? '3' : '3 1 0%',
              alignSelf: 'baseline',
            }}
            variant="body2"
          >
            {x}
            {isCopyable.length ? (
              <Box
                component="div"
                role="button"
                tabIndex={0}
                sx={{
                  '&:focus': {
                    borderColor: '#54BDCB',
                    borderWidth: '2px',
                    borderRadius: '4px',
                    borderStyle: 'solid',
                  },
                }}
                key={x}
                display="inline"
                ml={2}
              >
                <CopyItem item={isCopyable} />
              </Box>
            ) : null}
            <ConditionallyRender
              condition={isHistory}
              when={historyData.map((historyUnit, i) => (
                <div key={(i, Math.random())} style={{ marginTop: '1rem' }}>
                  <ShowHideAllWrapper
                    isHistory
                    setIsShowAll={setIsShowAllHistory}
                    isShowAll={isShowAllHistory}
                    amountOfShowedItems={0}
                    iteration={i}
                    dataLength={historyData?.length}
                  >
                    <React.Fragment key={(i, historyUnit?.data)}>
                      <Typography variant="body2" sx={{ color: 'grey.600' }}>
                        {historyUnit.data}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'grey.600' }}>
                        {`(${moment(historyUnit?.valid_from).format('DD.MM.YYYY')}  ${
                          historyUnit?.valid_to
                            ? `-${moment(historyUnit?.valid_to).format(' DD.MM.YYYY')}`
                            : ''
                        })`}
                      </Typography>
                    </React.Fragment>
                  </ShowHideAllWrapper>
                </div>
              ))}
            />
          </Typography>
        </React.Fragment>
      ))}
    </div>
  )
}

export default SimpleEntry
