import { Stack, Divider } from '@mui/material'
import { ReactSetState } from 'types/general'
import { useBuilder } from '../../../../BuilderContext'
import intl from 'localization/components'
import { FormattedMessage, useIntl } from 'react-intl'
import SidePanelHeader from '../SidePanelHeader'
import { InputSearchText } from 'components-new'

type PropTypes = {
  text: string
  setText: ReactSetState<string>
}

const ActionPanelSearch = ({ text, setText }: PropTypes) => {
  const { actions } = useBuilder()
  const { messages } = useIntl()
  return (
    <Stack>
      <SidePanelHeader
        title={<FormattedMessage id={intl.creditPolicyNew('select-action')} />}
        onClose={() => actions.actionPanelToggle({ open: false })}
      />
      <Divider />
      <Stack padding={5}>
        <InputSearchText value={text} handleChange={setText} />
      </Stack>
    </Stack>
  )
}

export default ActionPanelSearch
