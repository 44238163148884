import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, ListItemIcon, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { navbarCloseMobile } from 'store_deprecated'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useDispatch } from 'react-redux'
import { useAppShell } from './context'
import { useAccountStatus } from 'services/queries'
import { NavigationItemTitle, NavigationItemPropsType } from './types'
import { StyledListItemText, StyledListItemButton } from 'styles/components/sidebar'

// Create .model file and move it there
const freemiumRestricted = {
  home: false,
  'credit-check': false,
  'risk-monitoring': false,
  'company-comparison': true,
  'relation-overview': false,
  'credit-policy': true,
  'advanced-filters': true,
  'enterprise-credit-policy': true,
  'customer-management': true,
  faq: false,
}

function NavigationItem({
  title,
  url,
  icon,
  disabled = false,
  ...props
}: NavigationItemPropsType) {
  const [isFocused, setIsFocused] = useState(false)
  const dispatch = useDispatch()
  const appShell = useAppShell()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const accountStatusQuery = useAccountStatus()

  const isFreemiumRestricted =
    accountStatusQuery.data?.subscription_plan === 'FREEMIUM' && freemiumRestricted[title]

  function handleNavigationClose() {
    dispatch(navbarCloseMobile())
    appShell.setSearchActive(false)
  }

  const handleOpenSearchInstead = (title: NavigationItemTitle) => {
    if (title === 'credit-check') {
      appShell.setResultType('company')
    } else if (title === 'relation-overview') {
      appShell.setResultType('person')
    }
    dispatch(navbarCloseMobile())
    appShell.setSearchActive(true)
  }

  return (
    <Box data-cy={title}>
      <Link
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={{
          textDecoration: 'none',
          display: 'block',
          backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
        }}
        to={url}
        key={title}
        onClick={(e) =>
          disabled || isFreemiumRestricted ? e.preventDefault() : handleNavigationClose()
        }
        {...props}
      >
        <StyledListItemButton
          tabIndex={-1}
          selected={location.pathname.includes(url)}
          onClick={() => (disabled ? handleOpenSearchInstead(title) : null)}
          disabled={isFreemiumRestricted}
          data-cy={`navigation-button-${title}`}
        >
          <ListItemIcon>
            {icon}
            <Box mt={isSmall ? 0 : 1} textAlign={isSmall ? 'left' : 'center'}>
              <StyledListItemText>
                <FormattedMessage id={intl.navigation(title)} />
              </StyledListItemText>
            </Box>
          </ListItemIcon>
        </StyledListItemButton>
      </Link>
    </Box>
  )
}

export default NavigationItem
