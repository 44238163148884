import { Box, Grid, Typography } from '@mui/material'
import BoxContainerSmall from 'components-new/layouts/BoxContainerSmall'
import { model } from './PortfolioRiskDistribution.model'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { useMonitorShowList } from 'services/queries'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { riskDistributionPropTypes } from './PortfolioRiskDistribution.types'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { classJustifyContentCenter } from 'styles-new/global/helpers'
import { classOutlineBoxTarget } from 'styles-new/global/components'
import RadialPercentChart from 'components-new/charts/RadialPercentChart'
import { riskIntelligenceApiKeys as apiKeys } from 'configs/constants/api'
import { subLabel } from 'styles-new/global/typography'

const PortfolioRiskDistribution = ({
  listId,
  handlePortfolioInsightsFilters,
}: {
  listId: string
  handlePortfolioInsightsFilters: (category: string, key: string) => void
}) => {
  const reactIntl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const monitorShowListQuery = useMonitorShowList()

  const boxData = {
    title: intl.portfolioInsights('portfolio-risk-distribution'),
    status: {
      isLoading: monitorShowListQuery.isLoading,
      isError: monitorShowListQuery.isError,
      isIdle: monitorShowListQuery.isIdle,
    },
    height: 200,
    outlineBox: false,
    skeletonType: 'CHART_WITH_TEXT',
  }

  const calculateData = () => {
    // finding the selected lists
    const selectedList = monitorShowListQuery.data?.find(
      (list: { list_id: number }) => list.list_id === Number(listId)
    )
    // calculating all companies
    const allCompanies = Object.values(selectedList?.risk_assessment as object).reduce(
      (a, b) => a + b
    )
    // building data using the model
    return model.map((item: riskDistributionPropTypes) => {
      const riskCompanies =
        selectedList?.risk_assessment[
          item.risk as keyof typeof selectedList.risk_assessment
        ] || 0
      return {
        ...item,
        value: (riskCompanies / allCompanies) * 100,
        subTitleText: reactIntl.formatMessage(
          {
            id: intl.portfolioInsights('portfolio-risk-distribution-subtitle'),
          },
          {
            currentCompaniesCount: riskCompanies,
            allCompaniesCount: allCompanies,
          }
        ),
      }
    })
  }

  return (
    <Box sx={{ mt: 2 }}>
      <BoxContainerSmall boxData={boxData}>
        {!boxData.status.isLoading &&
        !boxData.status.isIdle &&
        !boxData.status.isError ? (
          <>
            {calculateData().map((risk: riskDistributionPropTypes) => {
              return (
                <Box sx={{ ...classOutlineBoxTarget, mb: 2 }} key={risk.titleText}>
                  {risk.value > 0 && (
                    <Grid
                      container
                      onClick={() =>
                        handlePortfolioInsightsFilters(apiKeys.creditScore, risk.key)
                      }
                    >
                      <Grid item xs>
                        <RadialPercentChart
                          value={risk.value}
                          fill={risk.fill}
                          outerRadius={50}
                          barSize={5}
                          cornerRadius={50}
                        />
                      </Grid>
                      <Grid item xs={8} sx={classJustifyContentCenter}>
                        <Typography component="div">
                          <Box sx={subLabel}>
                            {
                              <FormattedMessage
                                id={intl.portfolioInsights(risk.titleText)}
                              />
                            }
                          </Box>
                        </Typography>
                        <Typography variant="subtitle1">{risk.subTitleText}</Typography>
                      </Grid>
                      {!isMobile && (
                        <Grid item xs={1} sx={classJustifyContentCenter}>
                          <ArrowForwardIosIcon color="disabled" />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Box>
              )
            })}
          </>
        ) : null}
      </BoxContainerSmall>
    </Box>
  )
}

export default PortfolioRiskDistribution
