import React from 'react'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import { Stack, Typography } from '@mui/material'
import './textBlockHandler.css'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const removeHTMLBoldTags = (text) => {
  const regex = /<b>|<\/b>/g
  return text?.replace(regex, '')
}

// where there is a <br /> tag make more <br /> tags
const addBreaks = (text) => {
  return text?.replace(/<br \/>/g, '<br /><br />')
}

// add inline styling to h4 tags

const addInlineStyling = (text) => {
  const regex = /<h4>/g
  return text?.replace(regex, `<h4 class="mui-h4">`)
}

const formatHTML = (text, removeBold, addNewLine) => {
  if (!removeBold && !addBreaks) return text
  let newText = text
  if (removeBold) newText = removeHTMLBoldTags(newText)
  if (addNewLine) newText = addBreaks(newText)
  newText = addInlineStyling(newText)
  return newText
}

const TextBlockHandler = ({ data, language, removeBoldTags, addNewLine }) => {
  return (
    <Stack gap={6}>
      {data
        ? data.map((x, i) => (
            <Stack key={(x.title, i)}>
              {x.title && (
                <Typography variant="h4">
                  {<FormattedMessage id={intl.faq(x.title)} />}
                </Typography>
              )}
              <Typography variant="body1">
                {ReactHtmlParser(formatHTML(x[language], removeBoldTags, addNewLine))}
              </Typography>
            </Stack>
          ))
        : ''}
    </Stack>
  )
}

const mapStateToProps = (state) => ({
  language: state.auth.user.local.lang,
})

export default connect(mapStateToProps)(TextBlockHandler)
