import { useSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import { creditPolicyGet } from 'services/api'
import { CreditPolicyT } from 'services/api/endpoints/risikaAPI/creditPolicyGet'
import { RisikaApiError } from 'types/general'

export function useSinglePolicy(country: string) {
  const { enqueueSnackbar } = useSnackbar()

  return useQuery<CreditPolicyT, RisikaApiError>(
    ['singlePolicy', country],
    () => creditPolicyGet(country),
    {
      // onError: (err) => {
      //   enqueueSnackbar(`${err?.response?.data?.error}`, {
      //     variant: 'error',
      //   })
      // },
      refetchOnWindowFocus: false,
    }
  )
}
