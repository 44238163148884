import { useMemo } from 'react'
import { Stack } from '@mui/material'
import PolicyCard from './widgets/PolicyCard'
import { PropsType } from './PolicyCards.types'
import { mockedDataPolicy } from './PolicyCards.model'
import { useQueryClient } from 'react-query'
import { UsersType, UseUserSelfResponse } from 'types/queries'
import { useAllUsers } from 'services/queries'
import { Policy } from 'types/general'

/** There is no controller so lets leave this here for now. Move it if you create a controller at any point in time
 * Returns sorted policy data with owner names.
 * @param {Policy[]} cardData - Array of policy data.
 * @param {UsersType} allUsers - Array of all users.
 * @returns {Object[]} - Sorted policy data with owner names.
 */
function getSortedDataWithOwnerName(cardData: Policy[], allUsers: UsersType) {
  const ownerMap = new Map() // Map to cache owner names based on owner_id
  const data = [...cardData] // Create a copy of cardData array to avoid mutation

  // Sort the data based on the updated property
  data.sort((a, b) => {
    const updatedA = new Date(a.updated)
    const updatedB = new Date(b.updated)

    if (updatedA && updatedB) {
      return updatedB.getTime() - updatedA.getTime()
    }
    return 0
  })

  // Add the owner name based on the api_user_id property from allUsers array
  return data.map((policy) => {
    let ownerName

    if (ownerMap.has(policy.owner_id)) {
      ownerName = ownerMap.get(policy.owner_id)
    } else {
      const owner = allUsers.find((user) => user.api_user_id === policy.owner_id)
      ownerName = owner?.name ?? owner?.email ?? policy.owner_id
      ownerMap.set(policy.owner_id, ownerName)
    }

    return {
      ...policy,
      owner_name: ownerName,
    }
  })
}

const PolicyCards = ({ data, loading }: PropsType) => {
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData('userSelf') as UseUserSelfResponse
  const role = userData?.role
  const { data: allUsers, isLoading: usersLoading } = useAllUsers()

  const policyCardData = useMemo(() => {
    // TODO: Return appropriate message/loading spinner instead of an []
    if (loading || usersLoading || !data || !allUsers) return []
    if (role !== 'admin') {
      return getSortedDataWithOwnerName(
        data?.filter((policy) => policy?.enabled === true),
        allUsers
      )
    }
    return getSortedDataWithOwnerName(data, allUsers)
  }, [allUsers, data, loading, role, usersLoading])

  return (
    <Stack sx={{ mb: '16rem' }} spacing={4}>
      {policyCardData?.map((cardData) => {
        return <PolicyCard loading={loading} key={cardData?.id} cardData={cardData} />
      })}
    </Stack>
  )
}

export default PolicyCards
