import moment from 'moment'

export const formatToUniversalDate = (date: string, withoutYear?: boolean) => {
  if (!date) {
    return '-'
  }

  let sanitisedString = date

  if (typeof date === 'string') {
    sanitisedString = date.replace(/[_-]/g, '.')
  }

  const sanitisedDate = moment(
    sanitisedString,
    [
      'YYYY.MM.DD',
      'DD.MM.YYYY',
      'MM.DD.YYYY',
      'YYYY/MM/DD',
      'DD/MM/YYYY',
      'MM/DD/YYYY',
      'YYYY.MM',
      'YYYY/MM',
    ],
    true // strict parsing option
  )
  const formattedDate = withoutYear
    ? moment(sanitisedDate).format('DD/MM')
    : moment(sanitisedDate).format('DD/MM/YYYY')

  if (formattedDate === 'Invalid date') {
    return '-'
  } else {
    return formattedDate
  }
}
