import { isCallAllowed } from 'configs'
import { AvailableCountries } from 'globalTypes'
import { useQuery } from 'react-query'
import { searchPerson } from 'services/api'
import { PersonSearch } from '../api/endpoints/risikaAPI/searchPerson'
import { ErrorResponse } from 'types/general'

export function usePersonSearch(searchText: string, country: AvailableCountries) {
  return useQuery<PersonSearch, ErrorResponse>(
    ['search', 'person', searchText],
    () => searchPerson({ searchText, country }),
    {
      enabled: searchText.length >= 3 ? !!isCallAllowed('searchPerson', country) : false,
      staleTime: 5 * 60 * 1000,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
