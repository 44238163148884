import PortfolioScore from './widgets/PortfolioScore'
import PortfolioRiskDistribution from './widgets/PortfolioRiskDistribution'
import PortfolioRiskHistory from './widgets/PortfolioRiskHistory'
import PointsOfAttention from './widgets/PointsOfAttention'

const Summary = ({
  listId,
  handlePortfolioInsightsFilters,
}: {
  listId: string
  handlePortfolioInsightsFilters: (category: string, key: string | []) => void
}) => {
  return (
    <>
      <PortfolioScore listId={listId} />
      <PortfolioRiskDistribution
        listId={listId}
        handlePortfolioInsightsFilters={handlePortfolioInsightsFilters}
      />
      <PortfolioRiskHistory listId={listId} />
      <PointsOfAttention
        listId={listId}
        handlePortfolioInsightsFilters={handlePortfolioInsightsFilters}
      />
    </>
  )
}

export default Summary
