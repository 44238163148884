// @flow

import { Typography } from '@mui/material'

export const Company = ({ primary, secondary, isActive, isPolicy, dataCy = '' }) => {
  return (
    <div data-cy={dataCy}>
      <Typography variant="subtitle1" sx={{ color: isActive ? 'gry.800' : 'grey.600' }}>
        {primary}
      </Typography>
      {secondary ? (
        <Typography variant="body2" sx={{ color: isActive ? 'grey.800' : 'grey.600' }}>
          {secondary}
        </Typography>
      ) : null}
    </div>
  )
}

export default Company
