import { RouteComponentProps, withRouter } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { IntlReactTable } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { Typography } from '@mui/material'
import { take } from 'ramda'
import { PersonSearchResult } from 'services/api/endpoints/risikaAPI/searchPerson'

type ChildrenValues = { props: { children: string } }

const COMPANIES_TO_SHOW = 5

/**
 * Throws an error when trying to do it directly...
 */

const len = (arr: any[]) => arr?.length ?? 0

interface PeopleTableProps extends RouteComponentProps {
  data: PersonSearchResult[]
  handleSelect: (isActive: boolean) => void
  rowCount: number
}

export const PeopleTable = ({
  data,
  history,
  handleSelect,
  rowCount,
}: PeopleTableProps) => {
  if (!data || !data.map) data = []
  const map = (data: PersonSearchResult[]) =>
    data.map((row) => ({
      ...row,
      display_name: <Typography variant="subtitle1">{row.name || ''}</Typography>,
      display_active_companies_count: (
        <Typography variant="body2">{len(row.active_company_relations)}</Typography>
      ),
      display_company_roles: (
        <Typography variant="body2">
          {row.functions ? row.functions.map((fn) => fn.title).join(', ') : ''}
        </Typography>
      ),
      display_companies: (
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="body2"
        >
          {take(COMPANIES_TO_SHOW, row.active_company_relations).join(', ')}
        </Typography>
      ),
    }))

  // @ts-ignore _state is not being used in this function, but received as a parameter, so it does not require a type
  const handleClickOnRow = (_state: any, rowInfo: { original: PersonSearchResult }) => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        handleSelect(false)

        if (!rowInfo) return
        const id = rowInfo.original.personal_id
        history.push(
          navigation.relationOverview({ country: rowInfo.original.country ?? 'dk', id })
        )
      },
    }
  }

  return (
    <IntlReactTable
      data={map(data)}
      filterable={false}
      className={'-striped -highlight border-0'}
      minRows={rowCount}
      getTdProps={handleClickOnRow}
      columns={[
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.generic('name')} />{' '}
            </Typography>
          ),
          accessor: 'display_name',
          sortMethod: (a: ChildrenValues, b: ChildrenValues) =>
            a?.props?.children > b?.props?.children ? 1 : -1,
          minWidth: 250,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.generic('companies')} />{' '}
            </Typography>
          ),
          accessor: 'display_companies',
          sortMethod: (a: ChildrenValues, b: ChildrenValues) =>
            a?.props?.children > b?.props?.children ? 1 : -1,
          minWidth: 400,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.generic('roles')} />{' '}
            </Typography>
          ),
          accessor: 'display_company_roles',
          sortMethod: (a: ChildrenValues, b: ChildrenValues) =>
            a?.props?.children > b?.props?.children ? 1 : -1,
          minWidth: 300,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.generic('active-companies')} />{' '}
            </Typography>
          ),
          accessor: 'display_active_companies_count',
          sortMethod: (a: ChildrenValues, b: ChildrenValues) =>
            a?.props?.children > b?.props?.children ? 1 : -1,
          minWidth: 200,
        },
      ]}
    />
  )
}

export default withRouter(PeopleTable)
