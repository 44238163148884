const sectionContainer = {
  gap: 6,
  paddingBottom: 6,
  marginBottom: 8,
}

const subSectionContainer = {
  gap: 6,
  paddingBottom: 6,
}

const customTypographyStyles = {
  fontFamily: 'Montserrat',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.15px',
}
export default { sectionContainer, subSectionContainer, customTypographyStyles }
